import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import facebook from '../../../assets/icons/Newicons/facebook.png';
import instagram from '../../../assets/icons/Newicons/instagram.png';
import * as APIController from '../../API/APIController';
import { clsAddEditSubscription } from '../../API/APIParameters';
import { Button, CommonLoader } from "../../components";
import Notification from '../../components/Notification/Notification';
import CommonModal from '../../components/modal/CommonModal';
import { Images } from '../../config';
import { GetDateWithMyFormat, IsEmpty, getItemStore, storageKeys } from '../../config/appConstant';
import PageSubTitle from '../PageSubTitle/PageSubTitle';
import './CustomCalender.css';
import Style from "./CustomCalenderStyle";

const CustomCalendar = (props) => {
    const { showHeader = true } = props; // Destructure showHeader prop with default value true

    const SubscriptionID = getItemStore(storageKeys.SubscriptionID)
    const LocationID = getItemStore(storageKeys.LocationIDs)
    const DAY = new Date();
    const MonthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [MonthData, setWeekDay] = React.useState([{ "ID": 1, "Name": "Sunday" }, { "ID": 2, "Name": "Monday" }, { "ID": 3, "Name": "Tuesday" }, { "ID": 4, "Name": "Wedsday" }, { "ID": 5, "Name": "Thursday" }, { "ID": 6, "Name": "Friday" }, { "ID": 7, "Name": "Saturday" }]);
    const [AllCalenderData, setAllCalenderData] = React.useState(props?.calenderData)
    const [IsLoading, setIsLoading] = React.useState(false);
    const [CurrentMonth, setCurrentMonth] = React.useState(DAY.getMonth() + 1);
    const [CurrentYear, setCurrentYear] = React.useState(DAY.getFullYear());
    const [CurrentMonthName, setCurrentMonthName] = React.useState(MonthNames[DAY.getMonth()]);
    const [TotalDayOfMonth, setTotalDayOfMonth] = React.useState([]);
    const [TotalCards, setTotalCards] = React.useState([]);
    const [firstDateWeek, setfirstDateWeek] = React.useState(new Date(DAY.getFullYear(), DAY.getMonth(), 1).getDay() + 1);
    const [BlurCards, setBlurCards] = React.useState(0);// for Card Color Blure purpose
    const [AssetArray, setAssetArray] = React.useState([]);// for API Pass purpose
    const [ProductTutorialLink, setProductTutorialLink] = useState("");
    const [isshow, setIsshow] = useState(true)

    useEffect(() => {
        GetCurrntMonth('First')
    }, [])

    useEffect(() => {
        setIsLoading(true)
        setAllCalenderData(props?.calenderData)
        setTimeout(() => {
            setIsLoading(false)
        }, 1000);
    }, [props?.calenderData])



    useEffect(() => {
        if (props.ChanngeProps == 1) {
            OnPressNext()
            props.handleChangeProps(-1, (MonthNames[CurrentMonth]), CurrentYear)
        }
        else if (props.ChanngeProps == 2) {
            OnPressPrevious()
            props.handleChangeProps(-1, (MonthNames[CurrentMonth]), CurrentYear)

        }
    }, [props?.ChanngeProps])

    const GetCurrntMonth = (val) => {
        setIsLoading(true)
        var pairs = [];
        for (let i = 0; i < new Date(DAY.getFullYear(), DAY.getMonth() + 1, 0).getDate(); i++) {
            pairs.push({ Date: i + 1 });
        }
        //Logic for Get TodalDays Of month from start Day of week to totalday
        var cards = [];
        let firstDay = new Date(DAY.getFullYear(), DAY.getMonth(), 1);
        let lastDay = new Date(DAY.getFullYear(), DAY.getMonth() + 1, 0);
        let StartDayofWeek = MonthData[firstDay.getDay()].Name
        let Indexxx = MonthData.findIndex(obj => obj.Name === StartDayofWeek);
        let TotalDaysOfMonth = pairs.length + Indexxx

        for (let i = 0; i < TotalDaysOfMonth; i++) {
            // new Date().getDate() == TotalCards[i].Date
            cards.push({ ID: i + 1, PreviewURL: 'https://cdn.mybauschreach.com/Images/AutomationAssets/30ad2c7-88d2-5f5-83f3-44706c55bfbc_thumb.png?v=1698642914' });
        }
        setTotalCards(cards)
        setBlurCards(Indexxx)
        setTotalDayOfMonth(pairs)
        setIsLoading(false)
        //-------------------------------------------------------------------
        { val != 'First' && props.handleCalendar(GetDateWithMyFormat(firstDay), GetDateWithMyFormat(lastDay)) }//fetch date from Api -Call Api
    }

    const nextMonth = (date, currentMonth, currentYear) => {
        setIsLoading(true)
        setCurrentMonth(currentMonth + 1)
        var current = ''
        if (currentMonth == 11) {
            current = new Date(date.getFullYear() + 1, -1, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(MonthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        } else {
            current = new Date(date.getFullYear(), currentMonth, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(MonthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        }
        var daysInMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate();
        var pairs = [];
        for (let i = 0; i < daysInMonth; i++) {
            pairs.push({ Date: i + 1 });
        }

        //Logic for Get TodalDays Of month from start Day of week to totalday
        var cards = [];
        let firstDay = new Date(current.getFullYear(), current.getMonth(), 1);
        let lastDay = new Date(current.getFullYear(), current.getMonth() + 1, 0);
        let StartDayofWeek = MonthData[firstDay.getDay()].Name
        let Indexxx = MonthData.findIndex(obj => obj.Name === StartDayofWeek);
        let TotalDaysOfMonth = pairs.length + Indexxx
        for (let i = 0; i < TotalDaysOfMonth; i++) {
            cards.push({ ID: i + 1 });
        }
        setTotalCards(cards)
        setBlurCards(Indexxx)
        //-----------------------------------------------------------------
        // props.handleCalendar(GetCMMSDateWithMyFormat(firstDay), GetCMMSDateWithMyFormat(lastDay))//fetch date from Api -Call Api
        setTotalDayOfMonth(pairs)
        props.GetFilterMonthData(current.getMonth() + 1, current.getFullYear())
        // setIsLoading(false)
    }

    const previousMonth = (date, currentMonth, currentYear) => {
        setIsLoading(true)
        setCurrentMonth(currentMonth - 1)
        var current = ''
        if (currentMonth == 1) {
            current = new Date(date.getFullYear(), -1, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(MonthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        } else {
            current = new Date(date.getFullYear(), currentMonth - 2, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(MonthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        }

        var daysInMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate();
        var pairs = [];
        for (let i = 0; i < daysInMonth; i++) {
            pairs.push({ Date: i + 1 });
        }

        //Logic for Get TodalDays Of month from start Day of week to totalday
        var cards = [];
        let firstDay = new Date(current.getFullYear(), current.getMonth(), 1);
        let lastDay = new Date(current.getFullYear(), current.getMonth() + 1, 0);
        let StartDayofWeek = MonthData[firstDay.getDay()].Name
        let Indexxx = MonthData.findIndex(obj => obj.Name === StartDayofWeek);
        let TotalDaysOfMonth = pairs.length + Indexxx
        for (let i = 0; i < TotalDaysOfMonth; i++) {
            cards.push({ ID: i + 1 });
        }
        setTotalCards(cards)
        setBlurCards(Indexxx)
        //--------------------------------------------------------------------
        // props.handleCalendar(GetCMMSDateWithMyFormat(firstDay), GetCMMSDateWithMyFormat(lastDay))//fetch date from Api -Call Api
        setTotalDayOfMonth(pairs)
        props.GetFilterMonthData(current.getMonth() + 1, current.getFullYear())
        setIsLoading(false)
    }

    const OnPressNext = () => {
        const currentYear = new Date().getFullYear();
        if (CurrentYear == currentYear && CurrentMonth < 12) {
            nextMonth(new Date(), CurrentMonth, CurrentYear);
        }
    }
    const OnPressPrevious = () => {
        const currentYear = new Date().getFullYear();
        if (CurrentYear == currentYear && CurrentMonth > 1) {
            previousMonth(new Date(), CurrentMonth, CurrentYear)
        }
    }


    const wsAddEditSubscription = (updatedAssets, CalendarID, CategoryID) => {
        const objParameter = new clsAddEditSubscription();
        let FacebookUserID = getItemStore(storageKeys.FacebookUserID)
        let FBPageData = JSON.parse(getItemStore(storageKeys.FBPageData))
        let IGPageData = JSON.parse(getItemStore(storageKeys.IGPageData))

        objParameter.ID = SubscriptionID;
        objParameter.CalendarID = CalendarID;
        objParameter.LocationID = JSON.parse(LocationID)[0];
        objParameter.FacebookUserID = FacebookUserID;
        objParameter.Facebookusername = FBPageData.name;
        objParameter.FacebookID = FBPageData.id;
        objParameter.FacebookAccess_Token = FBPageData.access_token;
        objParameter.Instagramusername = IGPageData.username;
        objParameter.InstagramID = IGPageData.id;
        objParameter.CategoryIDs = CategoryID;
        objParameter.Assets = updatedAssets;
        objParameter.ForceUpdate = true;

        APIController.AddEditSubscription(objParameter)
            .then((response) => {
                if (response.status == 1) {
                    handleNotification(true, response.message, 'success')
                } else {
                    handleNotification(true, response.message, 'error')
                }
            })
    }

    const transformData = (assetID, newStatus) => {
        const assetsArray = AllCalenderData.reduce((accumulator, item) => {
            if (Array.isArray(item.Assets) && item.Assets.length > 0) {
                accumulator.push(...item.Assets);
            }
            return accumulator;
        }, []);

        const updatedAssets = assetsArray.map(asset => {
            return {
                ID: asset.ID,
                Status: asset.Status
            };
        });
        setAssetArray(updatedAssets)
        wsAddEditSubscription(updatedAssets, assetsArray[0].CalendarID, assetsArray[0].CategoryID)
    };

    const transformDeleteData = (specificIndex) => {
        const newArray = [...AllCalenderData];
        const assetsArray = newArray[specificIndex].Assets;
        assetsArray.forEach(asset => {
            asset.Status = 3;
        });
        newArray[specificIndex].Assets = assetsArray;

        setAllCalenderData(newArray)
        setOpenPopup(false)
    }

    const [OpenPopup, setOpenPopup] = useState(false)
    const [DeleteIndex, setDeleteIndex] = useState("");

    const handleDeletePopup = (index, item) => {
        // !IsEmpty(item.Assets) && item.Assets.map((itm) => { })
        const hasPersonalAsset = item.Assets.some(item => item.IsPersonalAsset === 1);
        if (hasPersonalAsset) {
            handleNotification(true, "Delete", 'success')
        } else {
            setDeleteIndex(index)
        }
        setOpenPopup(true)
    }

    const handleDelete = () => {
        transformDeleteData(DeleteIndex)
    }
    const handleClose = () => {
        setOpenPopup(false)
    }

    const LeftDataClick = (e, index) => {
        if (index == 0 && !IsEmpty(ProductTutorialLink)) {
            window.open(ProductTutorialLink)
        }
    }

    const [ShowNotification, setShowNotification] = useState(false);
    const [Message, setMessage] = useState('');
    const [ToastType, setToastType] = useState('');
    const handleNotification = (val, msg, type) => {
        setShowNotification(val);
        setMessage(msg)
        setToastType(type);
    }
    const { classes } = props;
    const d = new Date();
    let name = MonthNames[d.getMonth()];

    const isFutureDate = (DateValue, AddDays) => {
        const isWeekend = (date) => {
            const day = date.getDay();
            return day === 6 || day === 0; // Saturday or Sunday
        };
    
        const addWorkingDays = (date, days) => {
            while (days > 0) {
                date.setDate(date.getDate() + 1);
                if (!isWeekend(date)) {
                    days--;
                }
            }
            return date;
        };
    
        const targetDate = new Date(DateValue);
        if (AddDays == "" || AddDays == undefined || AddDays == null) {
            return new Date().getTime() > targetDate.getTime();
        } else {
            const futureDate = addWorkingDays(new Date(), AddDays);
            return futureDate.getTime() < targetDate.getTime();
        }
    };
    
    

    return (
        <>
            {showHeader
                ? <div className='customcalender-main' >
                    <div className='customcalender-submain' >
                        <div className='customcalender-pagesubtitle' >
                            <PageSubTitle
                                isRightButton2={false}
                                isRightButton1={SubscriptionID == 0 ? true : false}
                                RightButtonText1='Add Post'
                                RightButtonClick1={() => transformData()}
                                CenterRightClick={() => OnPressNext(new Date(), CurrentMonth, CurrentYear)}
                                CenterLeftClick={() => OnPressPrevious(new Date(), CurrentMonth, CurrentYear)}
                                CenterContentText={CurrentMonthName + " " + CurrentYear}
                                LeftDataClick={LeftDataClick.bind(this)}
                                LeftData={[
                                    { icon: Images.Play_IC, cursorPointer: !IsEmpty(ProductTutorialLink), isDrop: false, tooltip: 'Click to watch a step-by-step tutorial video for this module, guiding you through its feature and functionality.', ShowTutorial: true },
                                    { icon: Images.lead_gen_ad, isDrop: false, tooltip: 'Lead generation post 💼📈', ShowTutorial: true },
                                    { icon: Images.multiple_posts, isDrop: false, tooltip: 'Multiple Posts same day', ShowTutorial: true },
                                    { icon: Images.Facebook_IC, isDrop: false, tooltip: 'Your Facebook credentials are validated and up-to-date.Social automation for your campaigns is currently active and running smoothly.', ShowTutorial: true },
                                    { icon: Images.Instagram_IC, isDrop: false, tooltip: 'Your Instagram credentials are validate and up-to-date.Social automation for your campaigns is currently active and running smoothly. ', ShowTutorial: true },
                                ]} />
                        </div>
                    </div>
                </div> : <></>}


            <div className='customcalender-daynamecontent' >
                <div className='customcalender-dayname' >
                    {MonthData.map((Item, value) => {
                        return (
                            <div key={value} id={!showHeader ? 'CalenderTopTitleContentView-day-location' : 'CalenderTopTitleContentView-day'} className={classes.CalenderTopTitleContentView} >{Item.Name}</div>
                        )
                    })}
                </div>
                <div className='customcalender-date'>
                    {IsLoading ? <CommonLoader /> :
                        TotalCards.map((item, index) => {
                            const calenderContentId = index < BlurCards ? "calender-content-view-gray" : "calender-content-view-white"
                            const calenderContentIdbyLocation = index < BlurCards ? "calender-content-view-gray-Location" : "calender-content-view-white-Location"
                            return (
                                <div key={item.id} className={classes.CalenderContentView} id={!showHeader ? calenderContentIdbyLocation : calenderContentId} >
                                    {AllCalenderData?.map((Item, Index) => {
                                        let CalenderDate = Item.DateOfCreation.split('-')[2]
                                        let CalenderFullDate = (moment(CalenderDate + "/" + CurrentMonthName + "/" + CurrentYear).format("YYYY-MM-DD"))
                                        return (
                                            Index == index - firstDateWeek + 1 &&
                                            <div key={Index}>
                                                <div className='customcalender-mapincss'>
                                                    {/* <div className='customcalender-empty-width'></div> */}
                                                    <div className={name == CurrentMonthName && new Date().getDate() == CalenderDate ? classes.CalenderCurrentContent : classes.CalenderInnerContent}>{CalenderDate}</div>
                                                    <div className='customcalender-empty-width'>
                                                        {Item.Assets.length && Item.Assets[0].IsLeadGenPost == 1 && Item.Assets[0].Status != 3 ? <img style={{ height: '18px', marginRight: '5px' }} src={Images.lead_gen_ad} /> : <></>}
                                                        {Item.Assets.length > 1 && Item.Assets[0].Status != 3 && <img style={{ height: '20px' }} src={Images.multiple_posts} />}
                                                    </div>
                                                </div>
                                                <Divider className='customcalender-divider' />
                                                {(CalenderFullDate == Item.DateOfCreation && Item.Assets != '' && Item.Assets[0].Status != 3)
                                                    ? <div className={!showHeader ? 'content-box-calendar-location' : 'content-box-calendar'}>
                                                        <div className={!showHeader ? 'box-image-calendar calendar-image-box-Location' : 'box-image-calendar calendar-image-box'} style={{
                                                            backgroundImage: `url(${!IsEmpty(Item.Assets[0]) && Item.Assets[0].PreviewURL})`
                                                        }}>
                                                            {props.isButtonShow && <div className='button-hover'>
                                                                <div className='button-field-calendar'><Button round Medium type={3} title='Preview' onClick={() => props.handlePopup(true, "Preview", "View Social Post", Item.Assets)} /></div>
                                                                {SubscriptionID != 0
                                                                    ? <>
                                                                        {!isFutureDate(CalenderFullDate) && <div className='button-field-calendar'><Button round Medium type={3} title='Edit' onClick={() => props.handlePopup(true, "Edit", "Reschedule Post", Item.Assets)} /></div>}
                                                                        {isFutureDate(CalenderFullDate, 5) && <div className='button-field-calendar'><Button round Medium type={3} title='Add Post' onClick={() => { props.handlePopup(true, "Add", "Add Social Post", [], Item.DateOfCreation) }} /></div>}
                                                                    </>
                                                                    : <>
                                                                        <div className='button-field-calendar'><Button round Medium type={3} title='Delete' onClick={() => { handleDeletePopup(Index, Item) }} /></div>
                                                                    </>}
                                                            </div>}
                                                        </div>
                                                    </div>
                                                    : props.isButtonShow && SubscriptionID != 0 && isFutureDate(CalenderFullDate, 5) &&
                                                    <div className="content-box-calendar">
                                                        <div className='button-hover'>
                                                            <div className='button-field-calendar'><Button round Medium type={3} title='Add Post' onClick={() => props.handlePopup(true, "Add", "Add Social Post", [], Item.DateOfCreation)} /></div>
                                                        </div>
                                                    </div>}
                                            </div>)
                                    })}
                                </div>)
                        })
                    }
                </div>
            </div>
            {OpenPopup && <CommonModal
                maxWidth={'sm'}
                Title={"Delete Post"}
                ActionText={"Delete"}
                IsDeletePopup={true}
                onClose={handleClose.bind(this)}
                onSave={handleDelete.bind(this)} IsScroll >
            </CommonModal>}
            {ShowNotification &&
                <Notification
                    handleClose={handleNotification.bind(this)}
                    message={Message}
                    variant={ToastType}
                />}
        </>
    )
}

export default withStyles(Style)(CustomCalendar);
//Admin Side Start
export class clsGetNav {
  constructor() { }
}

export class clsGetTenantConfiguration {
  constructor() { }
}

// ACS
//Rights
export class clsGetRights {
  constructor() {
    this.Name = null;
    this.RightNo = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
export class clsGetRightForDropdown {
  constructor() { }
}
export class clsAddEditRight {
  constructor() {
    this.ID = null;
    this.RightNo = null;
    this.Name = null;
    this.IsActive = null;
  }
}
export class clsDeleteRight {
  constructor() {
    this.ID = null;
  }
}
export class clsGetRightByID {
  constructor() {
    this.ID = null;
  }
}

//Roles
export class clsGetRoles {
  constructor() {
    this.Name = null;
    this.RoleNo = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
export class clsGetRoleForDropdown {
  constructor() { }
}
export class clsAddEditRole {
  constructor() {
    this.ID = null;
    this.RoleNo = null;
    this.Name = null;
    this.IsActive = null;
    this.JSON = null;
  }
}
export class clsDeleteRole {
  constructor() {
    this.ID = null;
  }
}
export class clsGetRoleByID {
  constructor() {
    this.ID = null;
  }
}

//Screens
export class clsGetScreens {
  constructor() {
    this.ParentName = null;
    this.MenuPath = null;
    this.IsParent = null;
    this.IsTitle = null;
    this.IsActive = null;
    this.SequenceNo = null;
    this.Name = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
export class clsGetScreenForDropdown {
  constructor() { }
}
export class clsAddEditScreen {
  constructor() {
    this.ID = null;
    this.IsTitle = null;
    this.ParentID = null;
    this.IsActive = null;
    this.MenuPath = null;
    this.IsParent = null;
    this.SequenceNo = null;
    this.Name = null;
    this.JSON = null;
    this.ParamID = null;
  }
}
export class clsDeleteScreen {
  constructor() {
    this.ID = null;
  }
}
export class clsGetScreenByID {
  constructor() {
    this.ID = null;
  }
}
export class clsGetScreenForDropdownIsParent {
  constructor() {
    this.IsParent = null;
  }
}

// communication
// communications
export class clsGetCommunications {
  constructor() {
    this.EmailSubject = null;
    this.ContactName = null;
    this.EmailAddress = null;
    this.TypeName = null;
    this.NotificationAt = null;
    // this.IsRead = null;
    // this.ReadAt = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
export class clsSetCommunicationTemplate {
  constructor() {
    this.ID = null;
    this.HTML = null;
  }
}

//CommunicationConfiguration

export class clsDownloadCommunicationTemplate {
  constructor() {
    this.ID = null;
  }
}
export class clsAddEditCommunicationConfiguration {
  constructor() {
    this.ID = null;
    this.CommunicationTemplateID = null;
    this.UserIDs = null;
    this.RoleIDs = null;
    this.SendToSelf = null;
    this.SendSMS = null;
    this.SendEmail = null;
    this.SendBrowserNotification = null;
    this.SendAppNotification = null;
  }
}
export class clsDeleteCommunicationConfiguration {
  constructor() {
    this.ID = null;
  }
}
export class clsGetCommunicationConfigurationByID {
  constructor() {
    this.ID = null;
  }
}
export class clsGetCommunicationConfigurations {
  constructor() {
    this.CommunicationTemplateName = null;
    this.UserName = null;
    this.RoleName = null;
    this.SendToSelf = null;
    this.SendEmail = null;
    this.SendBrowserNotification = null;
    this.SendAppNotification = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
export class clsGetCommunicationConfigurationForDropdown {
  constructor() { }
}

//CommunicationTemplates

export class clsGetCommunicationTemplates {
  constructor() {
    this.TemplateName = null;
    this.EmailSubject = null;
    this.TypeID = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsAddEditCommunicationTemplate {
  constructor() {
    this.IsActive = null;
    this.EmailSubject = null;
    this.BrowserNotyTitle = null;
    this.ID = null;
    this.InAppTitle = null;
    this.SMSText = null;
    this.TypeModule = null;
    this.TemplateName = null;
    this.FriendlyTitle = null;
    this.PushNotyTitle = null;
    this.BrowserNotyName = null;
    this.EmailBody = null;
    this.TypeID = null;
    this.InAppNoty = null;
    this.PushNotyName = null;
  }
}

export class clsDeleteCommunicationTemplate {
  constructor() {
    this.ID = null;
  }
}

export class clsGetCommunicationTemplateByID {
  constructor() {
    this.ID = null;
  }
}
export class clsGetCommunicationTemplateForDropdown {
  constructor() {
    this.IsConfigurableByAdmin = null;
  }
}

// Tenant
//Tenant

export class clsGetTenants {
  constructor() {
    this.Name = null;
    this.CompanyName = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsAddEditTenant {
  constructor() {
    this.Name = null;
    this.CompanyName = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
export class clsDeleteTenant {
  constructor() {
    this.ID = null;
  }
}
export class clsGetTenantByID {
  constructor() {
    this.ID = null;
  }
}

//TenantSMTP
export class clsGetTenantSMTPForDropdown { }

export class clsGetTenantSMTPByID {
  constructor() {
    this.ID = null;
  }
}

export class clsGetTenantSMTP {
  constructor() {
    this.Host = null;
    this.Port = null;
    this.IsSSL = null;
    this.UserName = null;
    this.EmailFrom = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsDeleteTenantSMTP {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditTenantSMTP {
  constructor() {
    this.ID = null;
    this.Host = null;
    this.Password = null;
    this.Port = null;
    this.IsSSL = null;
    this.UserName = null;
    this.Password = null;
    this.EmailFrom = null;
  }
}
export class clsAdminNav {
  constructor() { }
}

//TenantDomain

export class clsAddEditTenantDomain {
  constructor() {
    this.ID = null;
    this.URL = null;
  }
}

export class clsDeleteTenantDomain {
  constructor() {
    this.ID = null;
  }
}

export class clsGetTenantDomainByID {
  constructor() {
    this.ID = null;
  }
}

export class clsGetTenantDomains {
  constructor() {
    this.URL = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
export class clsGetTenantDomainForDropdown {
  constructor() {
    this.URL = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
// APILogs
export class clsGetAPILogs {
  constructor() {
    this.MethodName = null;
    this.RequestAt = null;
    this.ResponseAt = null;
    this.Status = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

// GetChatGPTTransactions
export class clsGetChatGPTTransactions {
  constructor() {
    this.LocationName = null;
    this.UserName = null;
    this.RequestMessage = null;
    this.RequestAt = null;
    this.Total_Tokens = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

//Country
export class clsAddEditCountry {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.IsActive = null;
  }
}

export class clsDeleteCountry {
  constructor() {
    this.ID = null;
  }
}

export class clsGetCountryByID {
  constructor() {
    this.ID = null;
  }
}

export class clsGetCountries {
  constructor() {
    this.Name = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetCountryForDropdown {
  constructor() {
    this.Name = null;
  }
}

//State
export class clsGetStates {
  constructor() {
    this.Name = null;
    this.IsActive = null;
    this.CountryName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetStateForDropdown {
  constructor() {
    this.CountryID = null;
  }
}

export class clsGetStateByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteState {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditState {
  constructor() {
    this.ID = null;
    this.CountryID = null;
    this.Name = null;
    this.IsActive = null;
    this.CreatedByID = null;
  }
}

//Areas

export class clsGetAreas {
  constructor() {
    this.Name = null;
    this.ShortCode = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetAreaForDropdown { }

export class clsDeleteArea {
  constructor() {
    this.ID = null;
  }
}

export class clsGetAreaByID {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditArea {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.ShortCode = null;
    this.IsActive = null;
    this.CreatedByID = null;
  }
}

//Categories
export class clsGetCategoryForDropdown { }

export class clsGetCategoryByID {
  constructor() {
    this.ID = null;
  }
}

export class clsGetCategories {
  constructor() {
    this.Name = null;
    this.Description = null;
    this.SequenceNo = null;
    this.PasscreatorTemplateID = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsDeleteCategory {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditCategory {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.Description = null;
    this.SequenceNo = null;
    this.PasscreatorTemplateID = null;
    this.IsActive = null;
  }
}

//cities
export class clsGetCityForDropdown {
  constructor() {
    this.CountryID = null;
    this.StateID = null;
  }
}

export class clsGetCityByID {
  constructor() {
    this.ID = null;
  }
}

export class clsGetCities {
  constructor() {
    this.Name = null;
    this.IsActive = null;
    this.CountryName = null;
    this.StateName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsDeleteCity {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditCity {
  constructor() {
    this.ID = null;
    this.CountryID = null;
    this.StateID = null;
    this.Name = null;
    this.IsActive = null;
    this.CreatedByID = null;
  }
}

//Departments

export class clsGetDepartments {
  constructor() {
    this.Name = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetDepartmentForDropdown { }

export class clsGetDepartmentByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteDepartment {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditDepartment {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.IsActive = null;
    this.CreatedByID = null;
  }
}

//DocumentTypes
export class clsGetDocumentTypes {
  constructor() {
    this.Name = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
export class clsGetDocumentTypeForDropdown { }

export class clsGetDocumentTypeByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteDocumentType {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditDocumentType {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.CreatedByID = null;
    this.ProductID = null;
  }
}

//Document
export class clsGetDocuments {
  constructor() {
    this.ModuleName = null;
    this.Name = null;
    this.OriginalDocumentName = null;
    this.SizeInKB = null;
    this.DocType = null;
    this.Height = null;
    this.Width = null;
    this.LastModifiedAt = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
export class clsGetDocumentsForDropdown { }

export class clsGetDocumentsByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteDocument {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditDocument {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.CreatedByID = null;
  }
}

//Memberships
export class clsGetMemberships {
  constructor() {
    this.Name = null;
    this.IsDefault = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetMembershipForDropdown { }

export class clsGetMembershipByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteMembership {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditMembership {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.IsDefault = null;
    this.IsActive = null;
  }
}

//Positions
export class clsGetPositions {
  constructor() {
    this.Name = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetPositionForDropdown { }

export class clsGetPositionByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeletePosition {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditPosition {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.IsActive = null;
    this.CreatedByID = null;
  }
}

//PostageCharges

export class clsGetPostageCharges {
  constructor() {
    this.FromCount = null;
    this.ToCount = null;
    this.Cost = null;
    this.SequenceNo = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetPostageChargeForDropdown { }

export class clsGetPostageChargeByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeletePostageCharge {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditPostageCharge {
  constructor() {
    this.ID = null;
    this.FromCount = null;
    this.ToCount = null;
    this.Cost = null;
    this.SequenceNo = null;
    this.CreatedByID = null;
  }
}

//Segments
export class clsGetSegments {
  constructor() {
    this.Name = null;
    this.SequenceNo = null;
    this.IsDefault = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetSegmentForDropdown { }

export class clsGetSegmentByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteSegment {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditSegment {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.SequenceNo = null;
    this.IsDefault = null;
    this.IsActive = null;
    this.CreatedByID = null;
  }
}

//UserTypes
export class clsGetUserTypes {
  constructor() {
    this.Name = null;
    this.RoleNo = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetUserTypeForDropdown {
  constructor() {
    this.RoleNo = null;
  }
}

export class clsGetUserTypeByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteUserType {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditUserType {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.RoleNo = null;
    this.IsActive = null;
  }
}

//Regions
export class clsGetRegions {
  constructor() {
    this.Name = null;
    this.ShortCode = null;
    this.IsActive = null;
    this.AreaName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetRegionForDropdown {
  constructor() {
    this.AreaID = null;
  }
}

export class clsGetRegionByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteRegion {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditRegion {
  constructor() {
    this.ID = null;
    this.AreaID = null;
    this.Name = null;
    this.ShortCode = null;
    this.IsActive = null;
    this.CreatedByID = null;
  }
}

// SN Order
export class clsSyncOrders {
  constructor() {
  }
}

// SNStyles
export class clsGetSNStyles {
  constructor() {
    this.Name = null;
    this.SequenceNo = null;
    this.FontName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetSNStyleForDropdown { }

export class clsGetSNStyleByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteSNStyle {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditSNStyle {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.SequenceNo = null;
    this.FontName = null;
  }
}

// SN Gift Cards

export class clsGetSNGiftCards {
  constructor() {
    this.CardType = null;
    this.Name = null;
    this.Amount = null;
    this.SNID = null;
    this.SequenceNo = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetSNGiftCardForDropdown { }

export class clsGetSNGiftCardByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteSNGiftCard {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditSNGiftCard {
  constructor() {
    this.ID = null;
    this.CardType = null;
    this.Name = null;
    this.Amount = null;
    this.SNID = null;
    this.SequenceNo = null;
  }
}

// SN Addressbook

export class clsGetSNAddressBookByID {
  constructor() {
    this.ID = null;
    this.UserID = null;
  }
}

export class clsGetSNAddressBook {
  constructor() {
    this.ShipToNo = null;
    this.FirstName = null;
    this.LastName = null;
    this.BusinessName = null;
    this.Address = null;
    this.Address2 = null;
    this.City = null;
    this.State = null;
    this.Country = null;
    this.Zip = null;
    this.Birthday = null;
    this.Anniversary = null;
    this.TypeID = null;
    this.LastSyncAt = null;
    this.LocationName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsDeleteSNAddressBook {
  constructor() {
    this.ID = null;
    this.UserID = null;
  }
}

export class clsAddEditSNAddressBook {
  constructor() {
    this.ID = null;
    this.LocationID = null;
    this.FirstName = null;
    this.LastName = null;
    this.BusinessName = null;
    this.Address = null;
    this.Address2 = null;
    this.City = null;
    this.State = null;
    this.Country = null;
    this.Zip = null;
    this.Birthday = null;
    this.Anniversary = null;
    this.TypeID = null;
  }
}

// SN Templates

export class clsGetSNTemplates {
  constructor() {
    this.ShipToNo = null;
    this.Name = null;
    this.Message = null;
    this.LastsyncAt = null;
    this.LocationName = null;
    this.StyleName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetSNTemplateByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteSNTemplate {
  constructor() {
    this.ID = null;
  }
}
export class clsDownloadTemplate {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditSNTemplate {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.Message = null;
    this.StyleID = null;
    this.LocationID = null;
  }
}

// LN FilterLocationsForLNCampaign
export class clsFilterLocationsForLNCampaign {
  constructor() {
    this.LNCampaignID = null;
    this.ShipToNo = null;
    this.Name = null;
    this.EmailAddress = null;
    this.Address1 = null;
    this.CityName = null;
    this.StateName = null;
    this.Postcode = null;
    this.Membership = null;
    this.IsLocationVerified = null;
    this.OrderStatus = null;
    this.IsReviewNeeded = null;
    this.LastLoginBefore = null;
    this.RegistrationStatus = null;
    this.SubscriptionStatus = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
export class clsDeleteLocationFromLNCampaign {
  constructor() {
    this.ID = null;
    this.LocationIDs = null;
  }
}
export class clsAddLocationToLNCampaign {
  constructor() {
    this.ID = null;
    this.LocationIDs = null;
  }
}

// LN Campaigns
export class clsGetLNCampaigns {
  constructor() {
    this.CampaignTypeName = null;
    this.Name = null;
    this.Subject = null;
    this.LNTemplateName = null;
    this.TotalLocations = null;
    this.OrderNo = null;
    this.SendAfter = null;
    this.Status = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetLNCampaignByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteLNCampaign {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditLNCampaign {
  constructor() {
    this.ID = null;
    this.CampaignTypeID = null;
    this.OrderID = null;
    this.SendAfter = null;
    this.LNTemplateID = null;
    this.Name = null;
    this.Subject = null;
  }
}
export class clsStartStopCampaign {
  constructor() {
    this.ID = null;
    this.Reason = null;
  }
}

export class clsCancelCampaign {
  constructor() {
    this.ID = null;
    this.Reason = null;
  }
}

export class clsSyncMailWizz {
  constructor() {
    this.ID = null;
    this.MWListID = null;
    this.MWCampaignID = null;
  }
}

// LN Campaign History

export class clsGetLNCampaignHistory {
  constructor() {
    this.LNCampaignID = null;
    this.Remarks = null;
    this.CreatedAt = null;
    this.LNCampaignName = null;
    this.UserName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

// LN Camapign Details
export class clsGetLNCampaignDetailsByID {
  constructor() {
    this.LNCampaignID = null
    this.RoleName = null
  }
}


// LN Template
export class clsGetLNTemplates {
  constructor() {
    this.Name = null;
    this.Description = null;
    this.IsActive = null;
    // this.LastModifiedAt = null;
    this.LastModifiedByName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetLNTemplateForDropdown {

}

export class clsGetLNTemplateByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteLNTemplate {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditLNTemplate {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.Description = null;
    this.HTML = null;
    this.IsActive = null;
  }
}



//Territory

export class clsGetTerritoryForDropdown {
  constructor() {
    this.AreaID = null;
    this.RegionID = null;
    this.Name = null;
  }
}
export class clsGetTerritoryByID {
  constructor() {
    this.ID = null;
  }
}

export class clsGetTerritories {
  constructor() {
    this.Name = null;
    this.ShortCode = null;
    this.IsActive = null;
    this.AreaName = null;
    this.RegionName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsDeleteTerritory {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditTerritory {
  constructor() {
    this.ID = null;
    this.AreaID = null;
    this.RegionID = null;
    this.Name = null;
    this.ShortCode = null;
    this.IsActive = null;
    this.CreatedByID = null;
  }
}

//Zipcodes
export class clsGetZipcodes {
  constructor() {
    this.Postcode = null;
    this.Latitude = null;
    this.Longitude = null;
    this.TimeZone = null;
    this.IsActive = null;
    this.TimezoneOffset = null;
    this.StateName = null;
    this.CityName = null;
    this.CountryName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetZipcodeForDropdown {
  constructor() {
    this.Postcode = null;
  }
}

export class clsGetZipcodeByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteZipcode {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditZipcode {
  constructor() {
    this.ID = null;
    this.CountryID = null;
    this.StateID = null;
    this.CityID = null;
    this.Postcode = null;
    this.City = null;
    this.State = null;
    this.County = null;
    this.AreaCode = null;
    this.CityType = null;
    this.CityAliasName = null;
    this.Latitude = null;
    this.Longitude = null;
    this.TimeZone = null;
    this.Elevation = null;
    this.CountyFIPS = null;
    this.DayLightSaving = null;
    this.PreferredLastLineKey = null;
    this.ClassificationCode = null;
    this.MultiCounty = null;
    this.StateFIPS = null;
    this.CityStateKey = null;
    this.PrimaryRecord = null;
    this.CityMixedCase = null;
    this.CityAliasMixedCase = null;
    this.StateANSI = null;
    this.CountyANSI = null;
    this.FacilityCode = null;
    this.CityDeliveryIndicator = null;
    this.CarrierRouteRateSortation = null;
    this.FinanceNumber = null;
    this.CountyMixedCase = null;
    this.IsActive = null;
    this.TimezoneOffset = null;
  }
}

// GlobalActions
export class clsGetGlobalActions {
  constructor() {
    this.Name = null;
    this.ActionNo = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetGlobalActionForDropdown {
  constructor() {
    this.ProductID = null
  }
}

export class clsGetGlobalActionByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteGlobalAction {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditGlobalAction {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.ActionNo = null;
  }
}



//GlobalModules
export class clsGetGlobalModules {
  constructor() {
    this.Name = null;
    this.Description = null;
    this.SelectionTypeID = null;
    this.ShowAllCategories = null;
    this.IsAdBuilder = null;
    this.AddSelectAllLocation = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
export class clsGetGlobalModuleForDropdown { }

export class clsGetGlobalModuleByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteGlobalModule {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditGlobalModule {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.Description = null;
    this.HomeScreenURL = null;
    this.ShowAllCategories = null;
    this.IsAdBuilder = null;
    this.AddSelectAllLocation = null;
    this.AllowMultiSelectLocation = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.GlobalModuleTabs = null;
    this.GlobalActions = null;
  }
}

//GlobalSubModules
export class clsGetGlobalSubModules {
  constructor() {
    this.Name = null;
    this.Description = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.GlobalModuleName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetGlobalSubModuleForDropdown {
  constructor() {
    this.GlobalModuleID = null;
  }
}

export class clsGetGlobalSubModuleByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteGlobalSubModule {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditGlobalSubModule {
  constructor() {
    this.ID = null;
    this.GlobalModuleID = null;
    this.Name = null;
    this.AssetTypes = null;
    this.Description = null;
    this.SequenceNo = null;
    this.IsActive = null;
  }
}

//GlobalAssetTypes
export class clsGetGlobalAssetTypes {
  constructor() {
    this.Name = null;
    this.Description = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetGlobalAssetTypeForDropdown { }

export class clsGetGlobalAssetTypeByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteGlobalAssetType {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditGlobalAssetType {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.Description = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.AssetSizes = null;
  }
}

//GlobalAssetSizes
export class clsGetGlobalAssetSizes {
  constructor() {
    this.Name = null;
    this.Description = null;
    this.Height = null;
    this.Width = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetGlobalAssetSizeForDropdown { }

export class clsGetGlobalAssetSizeByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteGlobalAssetSize {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditGlobalAssetSize {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.Description = null;
    this.Height = null;
    this.Width = null;
    this.SequenceNo = null;
    this.IsActive = null;
  }
}

//Modules

export class clsGetModules {
  constructor() {
    this.GlobalModuleName = null;
    this.Name = null;
    this.Description = null;
    this.ShowAllCategories = null;
    this.IsAdBuilder = null;
    this.AddSelectAllLocation = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetModuleForDropdown { }

export class clsGetModuleByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteModule {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditModule {
  constructor() {
    this.ID = null;
    this.GlobalModuleID = null;
    this.Name = null;
    this.Description = null;
    this.ShowAllCategories = null;
    this.IsAdBuilder = null;
    this.AddSelectAllLocation = null;
    this.SequenceNo = null;
    this.IsActive = null;
  }
}

//SubModules
export class clsGetSubModules {
  constructor() {
    this.Name = null;
    this.Description = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.GlobalSubModuleName = null;
    this.ModuleName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetSubModuleForDropdown {
  constructor() {
    this.ModuleID = null;
  }
}

export class clsGetSubModuleByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteSubModule {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditSubModule {
  constructor() {
    this.ID = null;
    this.GlobalSubModuleID = null;
    this.ModuleID = null;
    this.AssetTypes = null;
    this.Name = null;
    this.Description = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.CreatedByID = null;
  }
}

//AssetTypes
export class clsGetAssetTypes {
  constructor() {
    this.Name = null;
    this.Description = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.GlobalAssetTypeName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetAssetTypeForDropdown { }

export class clsGetAssetTypeByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteAssetType {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditAssetType {
  constructor() {
    this.ID = null;
    this.GlobalAssetTypeID = null;
    this.Name = null;
    this.AssetSizes = null;
    this.Description = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.CreatedByID = null;
  }
}

//AssetSizes
export class clsAddEditAssetSize {
  constructor() {
    this.ID = null;
    this.GlobalAssetSizeID = null;
    this.Name = null;
    this.Height = null;
    this.Width = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.CreatedByID = null;
  }
}

export class clsDeleteAssetSize {
  constructor() {
    this.ID = null;
  }
}

export class clsGetAssetSizeByID {
  constructor() {
    this.ID = null;
  }
}

export class clsGetAssetSizeForDropdown { }

export class clsGetAssetSizes {
  constructor() {
    this.Name = null;
    this.Height = null;
    this.Width = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.GlobalAssetSizeName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

//Product
export class clsGetProducts {
  constructor() {
    this.Name = null;
    this.LevelNo = null;
    this.TagLine = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.ModuleName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetProductForDropdown { }

export class clsGetProductByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteProduct {
  constructor() {
    this.ID = null;
  }
}
export class clsGetProductTabs {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditProduct {
  constructor() {
    this.ID = null;
    this.ModuleID = null;
    this.Name = null;
    this.Title = null;
    this.SubTitle = null;
    this.Descriptions = null;
    this.LocationDescription = null;
    this.ProductFilters = null;
    this.TagLine = null;
    this.LevelNo = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.ShowOnHomeScreen = null;
    this.CoOpPercentage = null;
  }
}

// Product Actions
export class clsGetProductActions {
  constructor() {
    this.ProductID = null;
    this.Name = null;
    this.GlobalActionName = null;
    this.ProductName = null;
    this.SequenceNo = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetProductActionForDropdown {
  constructor() {
    this.GlobalActionID = null;
  }
}

export class clsGetProductActionByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteProductAction {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditProductAction {
  constructor() {
    this.ID = null;
    this.ProductID = null;
    this.GlobalActionID = null;
    this.Name = null;
    this.SequenceNo = null;
  }
}



//ProductCategories
export class clsGetProductCategoryForDropdown {
  constructor() {
    this.ProductID = null;
  }
}

export class clsGetProductCategoryByID {
  constructor() {
    this.ID = null;
  }
}

export class clsGetProductCategories {
  constructor() {
    this.SequenceNo = null;
    this.ProductID = null;
    this.CategoryName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsDeleteProductCategory {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditProductCategory {
  constructor() {
    this.ID = null;
    this.ProductID = null;
    this.CategoryID = null;
    this.SequenceNo = null;
  }
}

//ProductCategorySubCategories
export class clsGetProductCategorySubCategoryForDropdown {
  constructor() {
    this.ProductCategoryID = null;
  }
}

export class clsGetProductCategorySubCategoryByID {
  constructor() {
    this.ID = null;
  }
}

export class clsGetProductCategorySubCategories {
  constructor() {
    this.Name = null;
    this.SequenceNo = null;
    this.ProductID = null;
    this.IsActive = null;
    this.ProductCategoryName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsDeleteProductCategorySubCategory {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditProductCategorySubCategory {
  constructor() {
    this.ID = null;
    this.ProductCategoryID = null;
    this.Name = null;
    this.SequenceNo = null;
    this.IsActive = null;
  }
}

//globalfilters

export class clsGetGlobalFilters {
  constructor() {
    this.Name = null;
    this.Description = null;
    this.FilterNo = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetGlobalFilterForDropdown { }

export class clsGetGlobalFilterByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteGlobalFilter {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditGlobalFilter {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.Description = null;
    this.FilterNo = null;
    this.IsActive = null;
  }
}

//Filter

export class clsGetProductFilterForDropdown {
  constructor() {
    this.ID = null;
    this.ProductID = null;
  }
}

export class clsGetFilters {
  constructor() {
    this.Name = null;
    this.Description = null;
    this.IsActive = null;
    this.GlobalFilterName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetFilterForDropdown { }

export class clsGetFilterByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteFilter {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditFilter {
  constructor() {
    this.ID = null;
    this.GlobalFilterID = null;
    this.Name = null;
    this.Description = null;
    this.IsActive = null;
  }
}

//ProductFiltersDetails

export class clsGetProductFilterDetails {
  constructor() {
    this.Prefix = null;
    this.Name = null;
    this.Unit = null;
    this.ProductID = null;
    this.SequenceNo = null;
    this.AssetTypeName = null;
    this.FilterName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetProductFilterDetailForDropdown {
  constructor() {
    this.ProductFilterID = null;
    this.AssetTypeID = null;
  }
}

export class clsGetProductFilterDetailByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteProductFilterDetail {
  constructor() {
    this.ID = null;
    this.ProductFilterID = null;
  }
}

export class clsAddEditProductFilterDetail {
  constructor() {
    this.ID = null;
    this.ProductFilterID = null;
    this.AssetTypeID = null;
    this.Prefix = null;
    this.Name = null;
    this.Unit = null;
    this.SequenceNo = null;
  }
}

//BL_ST_LensTypes

export class clsGetBL_ST_LensTypes {
  constructor() {
    this.Name = null;
    this.IsDailyLens = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.CategoryName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetBL_ST_LensTypeForDropdown {
  constructor() {
    this.CategoryID = null;
  }
}

export class clsGetBL_ST_LensTypeByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteBL_ST_LensType {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditBL_ST_LensType {
  constructor() {
    this.ID = null;
    this.CategoryID = null;
    this.Name = null;
    this.IsDailyLens = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.CreatedByID = null;
  }
}

//BL_ST_LocationPricing

export class clsGetBL_ST_LocationPricingForDropdown { }

export class clsGetBL_ST_LocationPricingByID {
  constructor() {
    this.ID = null;
  }
}

export class clsGetBL_ST_LocationPricing {
  constructor() {
    this.PricePerBox = null;
    this.LastSyncAt = null;
    this.UserName = null;
    this.LensTypeName = null;
    this.LocationName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsDeleteBL_ST_LocationPricing {
  constructor() {
    this.ID = null;
    this.LocationID = null;
    this.UserID = null;
  }
}

export class clsAddEditBL_ST_LocationPricing {
  constructor() {
    this.ID = null;
    this.LocationID = null;
    this.UserID = null;
    this.LensTypeID = null;
    this.PricePerBox = null;
  }
}

//BL_ST_Configurations
export class clsGetBL_ST_Configurations {
  constructor() {
    this.PurchaseType = null;
    this.MinimumQuantityForAnnualSupply = null;
    this.LensTypeName = null;
    this.Price = null;
    this.MembershipName = null;
    this.CategoryName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetBL_ST_ConfigurationForDropdown {
  constructor() {
    this.SegmentID = null;
    this.CategoryID = null;
    this.LensTypeID = null;
  }
}

export class clsGetBL_ST_ConfigurationByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteBL_ST_Configuration {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditBL_ST_Configuration {
  constructor() {
    this.ID = null;
    this.MembershipID = null;
    this.CategoryID = null;
    this.LensTypeID = null;
    this.PurchaseType = null;
    this.MinimumQuantityForAnnualSupply = null;
    this.Price = null;
  }
}

//BL_ST_LocationPricingHistory
export class clsGetBL_ST_LocationPricingHistory {
  constructor() {
    this.PricePerBox = null;
    this.PriceAt = null;
    this.LocationName = null;
    this.LensTypeName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

//ProductAssets

export class clsGetProductAssetByID {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditProductAsset {
  constructor() {
    this.ID = null;
    this.ProductCategorySubCategoryID = null;
    this.AssetTypeID = null;
    this.AssetSizeID = null;
    this.AdContent = null;
    this.PrintAdContent = null;
    this.OrientationID = null;
    this.CreatedByID = null;
  }
}
export class clsDeleteProductAsset {
  constructor() {
    this.ID = null;
    this.TypeID = null;
  }
}

//ProductAssetsForUploading

export class clsGetProductAssetsForUploading {
  constructor() {
    this.ID = null;
    this.SubCategoryID = null;
  }
}

//Reports LoginLogs
export class clsGetUserLoginLogs {
  constructor() {
    this.StartDate = null;
    this.EndDate = null;
    this.RoleName = null;
    this.LocationName = null;
    this.FullName = null;
    this.EmailAddress = null;
    this.Address1 = null;
    this.Address2 = null;
    this.City = null;
    this.State = null;
    this.Postcode = null;
    this.ShipToNo = null;
    this.LoginAt = null;
    this.Memberships = null;

    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

//UserTerritories

export class clsGetUserTerritoryByID {
  constructor() {
    this.ID = null;
  }
}

export class clsGetUserTerritories {
  constructor() {
    this.UserID = null;
    this.AreaName = null;
    this.RegionName = null;
    this.TerritoryName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsDeleteUserTerritory {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditUserTerritory {
  constructor() {
    this.ID = null;
    this.UserID = null;
    this.TerritoryID = null;
  }
}

//Locations
export class clsGetLocations {
  constructor() {
    this.Name = null;
    this.ShipToNo = null;
    this.Address1 = null;
    this.PhoneNo = null;
    this.Website = null;
    this.IsCoOpEligible = null;
    this.Latitude = null;
    this.Longitude = null;
    this.IsLocationVerified = null;
    this.IsReviewNeeded = null;
    this.WalletURL = null;
    this.IsActive = null;
    this.CityName = null;
    this.StateName = null;
    this.PostcodeName = null;
    this.TerritoryName = null;
    this.LocationTypeID = null;
    this.StatusID = null;
    this.VerificationStatusID = null;
    this.ReviewStatusID = null;
    this.VTMName = null;
    this.ParentLocationName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetLocationByID {
  constructor() {
    this.ID = null;
  }
}
export class clsGetLocationSummary {
  constructor() {
    this.LocationIDs = null;
  }
}

export class clsDeleteLocation {
  constructor() {
    this.ID = null;
  }
}
export class clsLocation_GetRadius {
  constructor() {
    this.LocationIDs = null;
  }
}
export class clsLocation_SettRadius {
  constructor() {
    this.JSON = null;
  }
}

export class clsAddEditLocation {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.ShipToNo = null;
    this.Address1 = null;
    this.Address2 = null;
    this.PhoneNo = null;
    this.Website = null;
    this.MarketingEmailAddress = null;
    this.BusinessEmailAddress = null;
    this.FacebookURL = null;
    this.InstagramURL = null;
    this.PostcodeID = null;
    this.CityID = null;
    this.StateID = null;
    this.ParentLocationID = null;
    this.TerritoryID = null;
    this.IsCoOpEligible = null;
    this.SearchPostcode = null;
    this.SearchRadius = null;
    this.SearchSelection = null;
    this.AppointmentURL = null;
    this.Latitude = null;
    this.Longitude = null;
    this.GoogleAddress = null;
    this.WalletURL = null;
    this.WalletID = null;
    this.Title = null;
    this.CreatedByID = null;
    this.IsActive = null;
  }
}

//LocationMemberships
export class clsGetLocationMemberships {
  constructor() {
    this.LocationName = null;
    this.MembershipName = null;
    this.LocationID = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetLocationMembershipByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteLocationMembership {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditLocationMembership {
  constructor() {
    this.ID = null;
    this.LocationID = null;
    this.MembershipID = null;
  }
}

//Usres

export class clsGetUsers {
  constructor() {
    this.Name = null;
    this.EmailAddress = null;
    this.MobileNo = null;
    this.RoleID = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetUserByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteUser {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditUser {
  constructor() {
    this.ID = null;
    this.FirstName = null;
    this.LastName = null;
    this.Name = null;
    this.EmailAddress = null;
    this.MobileNo = null;
    this.AreaID = null;
    this.RegionID = null;
    this.TerritoryID = null;
    this.RoleID = null;
    this.SalesForceID = null;
    this.DepartmentID = null;
    this.PositionID = null;
    this.IsDummyUser = null;
    this.ShowDummyDashboard = null;
    this.CreatedByID = null;
    this.IsActive = null;
  }
}

export class clsAddEditAdminUser {
  constructor() {
    this.ID = null;
    this.FirstName = null;
    this.LastName = null;
    this.EmailAddress = null;
    this.MobileNo = null;
    this.RoleID = null;
    this.PositionID = null;
    this.DepartmentID = null;
    this.IsActive = null;
  }
}

//LPTemplates
export class clsGetLPTemplates {
  constructor() {
    this.Name = null;
    this.LastSyncAt = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.ShowInLocation = null;
    this.AssetTypeName = null;
    this.CategoryName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetLPTemplateByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteLPTemplate {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditLPTemplate {
  constructor() {
    this.ID = null;
    this.CategoryID = null;
    this.AssetTypeID = null;
    this.Name = null;
    this.AdContent = null;
    this.ShowInLocation = null;
    this.IsActive = null;
  }
}

export class clsLP_DownloadMasterTemplate {
  constructor() {
    this.ID = null;
  }
}
export class clsGenerateLandingPages {
  constructor() {
    this.ID = null;
  }
}

//LPReports

export class clsGetLPReports {
  constructor() {
    this.ReportDate = null;
    this.ReportedAt = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsDeleteLPReport {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditLPReport {
  constructor() {
    this.ID = null;
    this.StartDate = null;
    this.EndDate = null;
  }
}

export class clsImportAnalytics {
  constructor() {
    this.ReportDate = null;
  }
}

//LPHistory
export class clsGetLPHistory {
  constructor() {
    this.GenerationStartAt = null;
    this.GenerationEndAt = null;
    this.LPTemplatesID = null;
    this.UploadStartAt = null;
    this.UploadEndAt = null;
    this.NotifiedAt = null;
    this.NotifiedTo = null;
    this.Status = null;
    this.TemplateName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

//LPReportDetails
export class clsGetLPReportDetails {
  constructor() {
    this.LPReportID = null;
    this.TotalUsers = null;
    this.NewUsers = null;
    this.ActiveUsers = null;
    this.SessionsPerUser = null;
    this.AverageUserEngagementDuration = null;
    this.EngagementRate = null;
    this.BounceRate = null;
    this.screenPageViews = null;
    this.AppleWalletClick = null;
    this.GoogleWalletClick = null;
    this.AppointmentURLClick = null;
    this.DownloadClick = null;
    this.PagePath = null;
    this.LPLandingPageName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

//GetBugTracker

export class clsGetBugTracker {
  constructor() {
    this.IsResolved = null;
    this.BugNo = null;
    this.InnerException = null;
    this.IsNewBug = null;
    this.UserName = null;

    this.ErrorOccurredAt = null;
    this.Description = null;
    this.PageURL = null;
    this.CurrentPage = null;
    this.RecordPerPage = null;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
export class clsResolveBug {
  constructor() {
    this.ID = null;
    this.ResolutionDetails = null;
  }
}
export class clsGetBuyByID {
  constructor() {
    this.ID = null;
  }
}

export class clsGetDocumentByID {
  constructor() {
    this.ID = null;
  }
}

export class clsGetDocumentForDropdown {
  constructor() {
    this.ObjectID = null;
    this.DocumentTypeID = null;
  }
}
// sipply tools

export class clsGetBL_ST_LocationDetails {
  constructor() {
    this.ShipToNo = null;
    this.LocationName = null;
    this.Name = null;
    this.Address1 = null;

    this.City = null;
    this.State = null;
    this.Postcode = null;
    this.PhoneNo = null;
    this.LastModifiedAt = null;
    this.CurrentPage = null;
    this.RecordPerPage = null;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
export class clsAddEditBL_ST_LocationDetail {
  constructor() {
    this.LocationID = null;
    this.Name = null;
    this.Address1 = null;
    this.Address2 = null;
    this.City = null;
    this.State = null;
    this.Postcode = null;
    this.PhoneNo = null;
  }
}
export class clsGetBL_ST_LocationDetailByID {
  constructor() {
    this.ID = null;
  }
}
// Access History

export class clsGetBL_ST_AccessHistory {
  constructor() {
    this.ShipToNo = null;
    this.Address1 = null;
    this.LocationName = null;
    this.UserName = null;
    this.AccessedAt = null;
    this.CurrentPage = null;
    this.RecordPerPage = null;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
// lp

export class clsGetLPLandingPages {
  constructor() {
    this.URL = null;
    this.ShipToNo = null;
    this.Name = null;
    this.EmailAddress = null;
    this.Address1 = null;
    this.LastSyncAt = null;
    this.CategoryName = null;
    this.LPTemplateName = null;
    this.CurrentPage = null;
    this.RecordPerPage = null;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
//Admin Side end

// Frontend Side Parameters

export class clsGetHome { }
export class clsAddEditMyCustomPost {
  constructor() {
    this.ID = null;
    this.ScheduledAt = null;
    this.SubscriptionID = null;
    this.PostToInstagram = null;
    this.PostToFacebook = null;
    this.Description = null;
    this.IsFromLibrary = null;
  }
}
export class clsRescheduleMyPost {
  constructor() {
    this.ID = null;
    this.ScheduledAt = null;
    this.SubscriptionID = null;
  }
}
export class clsDeletePost {
  constructor() {
    this.ID = null;
    this.IsPersonalAsset = null;
    this.SubscriptionID = null;
  }
}
export class clsGetHomeDetails {
  constructor() {
    this.ID = null;
  }
}
export class clsGetAdBuilderAssets_SN {
  constructor() {
    this.ID = null;
    this.LocationID = null;
  }
}
export class clsGetActionLocationByID {
  constructor() {
    this.ID = null;
    this.ProductID = null;
  }
}
export class clsGetHomeSubCategories {
  constructor() {
    this.ID = null;
  }
}

export class clsGetActionLocations {
  constructor() {
    this.ProductID = null;
  }
}

export class clsGetAdBuilderAssets {
  constructor() {
    this.ID = null;
    this.LocationIDs = null;
  }
}

export class clsGetMyPromoWebsite {
  constructor() {
    this.ID = null;
  }
}
export class clsGetCart {
  constructor() {
    // this.ID = null;
  }
}

export class clsDeleteCart {
  constructor() {
    this.ID = null;
  }
}
export class clsCalculatePostageCharges {
  constructor() {
    this.TotalAddresses = null;
  }
}

export class clsDownloadCustomizableAsset {
  constructor() {
    this.FreeAssetID = null;
    this.ProductCategoryID = null;
    this.LocationID = null;
    this.TotalLocations = null;
    this.ProductCategorySubCategoryID = null;
    this.AssetTypeID = null;
    this.AssetID = null;
  }
}
export class clsAddEditCart {
  constructor() {
    this.ID = null;
    this.ProductCategorySubCategoryID = null;
    this.LocationIDs = null;
    this.CartFilters = null;

    // this.ProductCategoryID = null;
    // this.AssetTypeID = null;
    // this.AssetID = null;
  }
}
export class clsAddEditOrder {
  constructor() {
    this.OrderDetailsID = null;
    this.Budget = null;
    this.CampaignDurationInMonths = null;
    this.CampaignStartDate = null;
    this.CampaignEndDate = null;
    this.ProductID = null;
    this.CategoryID = null;
    this.ProductCategorySubCategoryID = null;
    this.ProductAssetID = null;
    this.LocationIDs = null;
    this.Filters = null;
    this.SimplyNotedParams = null;
  }
}
export class clsUpdateLocation_Featured_Location {
  constructor() {
    this.ID = null;
    this.ProductID = null;
    this.Name = null;
    this.Address1 = null;
    this.Address2 = null;
    this.City = null;
    this.State = null;
    this.Postcode = null;
    this.PhoneNo = null;
    this.Instructions = null;
    this.IsLocationsNearYou = null;
    this.Description = null;
    this.IsPhoneNo = null;
    this.IsInstructions = null;
    this.IsAddress1 = null;
  }
}
export class clsUpdateLocation_Location {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.Address1 = null;
    this.Address2 = null;
    this.City = null;
    this.State = null;
    this.Zipcode = null;
    this.PhoneNo = null;
    this.Website = null;
    this.AppointmentURL = null;
    this.EmailAddress = null;
    this.ShipToNo = null;
    this.Memberships = null;
    this.Latitude = null;
    this.Longitude = null;
    this.GoogleAddress = null;
    this.FacebookAccess_Token = null;
    this.FacebookUserID = null;
    this.Facebookusername = null;
    this.FacebookID = null;
    this.Instagramusername = null;
    this.ShowDocFinderInLeadGenPost = null;
  }
}

export class clsUpdateLocation {
  constructor() {
    {
      this.ID = null;
      this.ProductID = null;
      this.Name = null;
      this.Address1 = null;
      this.Address2 = null;
      this.City = null;
      this.State = null;
      this.Zipcode = null;
      this.PhoneNo = null;
      this.Website = null;
      this.AppointmentURL = null;
      // this.BusinessEmailAddress = null;
      this.Latitude = null;
      this.Longitude = null;
      this.GoogleAddress = null;
      this.Memberships = null;
      this.ShowDocFinderInLeadGenPost = null;
      this.AutomationSubscriptionID = null;
      this.FacebookAccess_Token = null;
      this.FacebookUserID = null;
      this.Facebookusername = null;
      this.FacebookID = null;
      this.Instagramusername = null;
      this.InstagramID = null;
    }
  }
}

export class clsUpdateLocation_SocialAutomation_SelectAll {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.PhoneNo = null;
    this.AutomationSubscriptionID = null;
    this.FacebookAccess_Token = null;
    this.FacebookUserID = null;
    this.Facebookusername = null;
    this.FacebookID = null;
    this.Instagramusername = null;
  }
}

export class clsGetCategorySubCategories {
  constructor() {
    this.ID = null;
  }
}

// Admin Calendar
export class clsGetCalendar {
  constructor() {
    this.CalendarYear = null;
    this.CalendarMonth = null;
    this.Cost = null;
    this.TotalAssets = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

// Calendar
export class clsGetMyCalendar {
  constructor() {
    this.ID = null;
    this.Month = null;
    this.Year = null;
  }
}
export class clsAddEditCalendar {
  constructor() {
    this.ID = null;
    this.PayPalSubscriptionPlanID = null;
    this.CalendarYear = null;
    this.CalendarMonth = null;
    this.IsActive = null;
  }
}
export class clsDeleteCalendar {
  constructor() {
    this.ID = null;
  }
}
export class clsGetCalendarByID {
  constructor() {
    this.ID = null;
  }
}

export class clsGeneratePostCopy {
  constructor() {
    this.Message = null;
    this.Prompt = null;
  }
}

export class clsAddEditSubscription {
  constructor() {
    this.ID = null;
    this.CalendarID = null;
    this.LocationID = null;
    this.FacebookUserID = null;
    this.Facebookusername = null;
    this.FacebookID = null;
    this.FacebookAccess_Token = null;
    this.Instagramusername = null;
    this.InstagramID = null;
    this.CategoryIDs = null;
    this.Assets = null;
    this.ForceUpdate = null;
  }
}

export class clsSubscribeNowWithoutPaypal {
  constructor() {
    this.ID = null;
  }
}
// DownloadFile

export class clsDownloadFile {
  constructor() {
    this.FileURL = null;
    this.ProductAssetID = null;
  }
}
export class clsGetProductAssetsForSocialAutomation {
  constructor() {
    this.Name = null;
    this.CurrentPage = null;
    this.SegmentName = null;
    this.RecordPerPage = null;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

//EditProfile

export class clsEditProfile {
  constructor() {
    this.ID = null;
    this.PhoneNo = null;
    this.FirstName = null;
    this.LastName = null;
    this.EmailAddress = null;
  }
}

//GetMyProfile

export class clsGetMyProfile { }

// ProductFreeAsset
export class clsProductFreeAssetBy {
  constructor() {
    this.ID = null;
  }
}
export class clsDeleteProductFreeAsset {
  constructor() {
    this.ID = null;
  }
}
export class clsAddEditProductFreeAsset {
  constructor() {
    this.ID = null;
    this.ProductCategoryID = null;
    this.ProductID = null;
    this.Name = null;
    this.Description = null;
    this.SequenceNo = null;
    this.MembershipID = null;
    this.VeevaCode = null;
    this.IsCustomizable = null;
    this.ExternalURL = null;
    this.ExpirationDate = null;
    this.ShowAssetsInSocialAutomation = null;
    this.IsNew = null;
    this.IsNewStartDate = null;
    this.IsNewEndDate = null;
  }
}
export class clsGetProductFreeAsses {
  constructor() {
    this.Name = null;
    this.SequenceNo = null;
    this.MembershipName = null;
    this.ProductCategorySubCategoryName = null;
    this.LastModifiedAt = null;
  }
}

//GlobalModuleTabs
export class clsGetGlobalModuleTabs {
  constructor() {
    this.Name = null;
    this.Description = null;
    this.SequenceNo = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetGlobalModuleTabForDropdown { }

export class clsGetGlobalModuleTabByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteGlobalModuleTab {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditGlobalModuleTab {
  constructor() {
    this.ID = null;
    this.Name = null;
    this.Description = null;
    this.SequenceNo = null;
    this.IsActive = null;
  }
}

// Media Library

export class clsGetDocumentsAsLibrary {
  constructor() {
    this.SearchText = null;
    this.DocumentTypeID = null;
  }
}
export class clsUpdateDocumentDescription {
  constructor() {
    this.ID = null;
    this.Description = null;
  }
}
export class clsSetDocumentAsFavourite {
  constructor() {
    this.ID = null;
  }
}

// calendar asset
export class clsGetCalendarAssets {
  constructor() {
    this.CalendarYear = null;
    this.CalendarMonth = null;
    this.CategoryName = null;
    this.Name = null;
    this.AssetDate = null;
    this.AssetDateTime = null;
    this.VeevaCode = null;
    this.IsLeadGenPost = null;
    this.LastModifiedAt = null;
    this.SequenceNo = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetCalendarAssetByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteCalendarAsset {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditCalendarAsset {
  constructor() {
    this.ID = null;
    this.CalendarID = null;
    this.CategoryID = null;
    this.Name = null;
    this.Description = null;
    this.AssetDate = null;
    this.AssetDateTime = null;
    this.VeevaCode = null;
    this.AssetTypeID = null;
    this.SequenceNo = null;
    this.IsLeadGenPost = null;
  }
}

// Tenant
export class clsAddEditTenantConfiguration {
  constructor() {
    this.ID = null;
    this.PageTitle = null;
    this.LoginHeaderWelcomeText = null;
    this.LoginTitle1 = null;
    this.LoginTitle2 = null;
    this.LoginCopyrightText = null;
    this.HeaderWelcomeText = null;
    this.Title1 = null;
    this.Title2 = null;
    this.CopyrightText = null;
    this.AllLocationDescriptionText = null;
    this.AllLocationInstructionText = null;
    this.ShowRegister = null;
    this.ShowHomeIcon = null;
    this.Theme = null;
    this.RegistrationTitle = null;
    this.RegistrationSubTitle = null;
    this.RegistrationMessage = null;
  }
}

export class clsGetTenantConfigurationByID {
  constructor() {
    this.ID = null;
  }
}

// CustomPostFeed

export class clsGetCustomPostAdminFeed {
  constructor() {
    this.StartDate = null;
    this.EndDate = null;
    this.IsFlagged = null;
    this.SortDirection = null;
    this.PracticeName = null;
  }
}
export class clsFlagCustomPost {
  constructor() {
    this.ID = null;
    this.IsFlagged = null;
  }
}

//keywordChecked
export class clsGetCalendarNegativeKeywords {
  constructor() {
    this.Name = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetCalendarNegativeKeywordByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteCalendarNegativeKeyword {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditCalendarNegativeKeyword {
  constructor() {
    this.ID = null;
    this.Name = null;
  }
}

// subscripition
export class clsGetSubscriptions {
  constructor() {
    this.SubscriptionNo = null;
    this.ShipToNo = null;
    this.LocationName = null;
    this.Postcode = null;
    this.StartDate = null;
    this.SubscribedAt = null;
    this.Timezone = null;
    this.SubscriptionStatus = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
//Schedule Posts
export class clsGetScheduledPosts {
  constructor() {
    this.SubscriptionNo = null;
    this.ShipToNo = null;
    this.LocationName = null;
    this.EmailAddress = null;
    this.Postcode = null;
    this.ScheduledDate = null;
    this.CredentialsStatus = null;
    this.IsPersonalAsset = null;
    this.IsSocialAutomationFree = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

// EmailLogs
export class clsGetSubscriptionEmailLogs {
  constructor() {
    this.EmailTo = null;
    this.EmailCC = null;
    this.EmailBCC = null;
    this.EmailSubject = null;
    this.EmailSentAt = null;
    this.Name = null;
    this.EmailSentByName = null;
    this.ShipToNo = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

// Subscription Scheduler History

export class clsGetSubscriptionSchedulerHistory {
  constructor() {
    this.ExecutedAt = null;
    this.Subscriptions = null;
    this.Deleted = null;
    this.ExpiredTokens = null;
    this.NotPosted = null;
    this.Facebook = null;
    this.Instagram = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
// Subscriptions Details
export class clsGetMySubscriptions { }

// Cost Estimates Created

export class clsGetSupplyToolDetails {
  constructor() {
    this.LeadAt = null;
    this.LeftLensType = null;
    this.RightLensType = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
export class clsGetScreenConfigurations { }

export class clsVerifyLocation {
  constructor() {
    this.ShipToNo = null;
    this.Postcode = null;
  }
}
export class clsRegisterLocation {
  constructor() {
    this.ShipToNo = null;
    this.Postcode = null;
    this.JSON = [];
    this.WebsiteURL = null;
    this.AppointmentURL = null;
    this.PhoneNo = null;
    this.FirstName = null;
    this.LastName = null;
    this.EmailAddress = null;
  }
}
// PayPalSubscription

export class clsGetPayPalSubscriptionPlans {
  constructor() {
    this.PlanID = null;
    this.Cost = null;
    this.Name = null;
    this.StartDate = null;
    this.IsActive = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
export class clsGetPayPalSubscriptionPlanForDropdown { }

export class clsDeletePayPalSubscriptionPlan {
  constructor() {
    this.ID = null;
  }
}

export class clsGetPayPalSubscriptionPlanByID {
  constructor() {
    this.ID = null;
    this.PlanID = null;
  }
}

export class clsAddEditPayPalSubscriptionPlan {
  constructor() {
    this.ID = null;
    this.PlanID = null;
    this.Cost = null;
    this.Name = null;
    this.StartDate = null;
    this.IsActive = null;
  }
}
// Leads
export class clsGetLeads {
  constructor() {
    this.ID = null;
    this.ShipToNo = null;
    this.Name = null;
    this.Address = null;
    this.LocationName = null;
    this.Location = null;
    this.Category = null;
    this.LeadGeneratedAt = null;
    this.EmailAddress = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
export class clsGetSupplyCostCalculatorLead {
  constructor() {
    this.LocationName = null;
    this.UserName = null;
    this.EmailAddress = null;
    this.LeadAt = null;
    this.R_L_PricePerBox = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

//AddEditSupplyCostCalculatorLead

export class clsAddEditSupplyCostCalculatorLead {
  constructor() {
    this.LeadAt = null;
    this.R_InsuranceCoverage = null;
    this.ImageData = null;
    this.R_RebatePrice = null;
    this.L_InsuranceCoverage = null;
    this.LeadType = null;
    this.R_TotalBoxes = null;
    this.L_YouPay = null;
    this.IsDailyLense = null;
    this.R_RetailPrice = null;
    this.L_LenseTypeID = null;
    this.ID = null;
    this.R_YouPay = null;
    this.R_LenseTypeID = null;
    this.L_PricePerBox = null;
    this.ImageURL = null;
    this.ImageName = null;
    this.L_RebatePrice = null;
    this.L_AdditionalDiscount = null;
    this.L_RetailPrice = null;
    this.L_TotalBoxes = null;
    this.R_PricePerBox = null;
    this.R_AdditionalDiscount = null;
    this.L_R_PricePerBox = null;
    this.PurchaseType = null;
    this.R_L_PricePerBox = null;
  }
}
export class clsSetSupplyToolPracticePricing {
  constructor() {
    this.ID = null;
    this.JSON = null;
    this.Name = null;
    this.Address1 = null;
    this.Address2 = null;
    this.City = null;
    this.State = null;
    this.Postcode = null;
    this.PhoneNo = null;
  }
}
// Locatioon master
export class clsGetLocationMaster {
  constructor() {
    this.Name = null;
    this.Address1 = null;
    this.Address2 = null;
    this.City = null;
    this.State = null;
    this.BillTo = null;
    this.Territory = null;
    this.ShipToNo = null;
    this.ZipCode = null;
    this.Customer = null;
    this.VSP = null;
    this.ImportedAt = null;
    this.IsAccountCreated = null;
    this.AccountCreatedAt = null;
    this.VTMName = null;
    this.LocationName = null;
    this.UserName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsDeleteLocationMaster {
  constructor() {
    this.ID = null;
  }
}

// Orders
export class clsGetOrderReportIDs {
  constructor() {
    this.dsUser = null;
    this.dsAccounts = null;
    this.CampaignID = null;
    this.ReportTypeID = null;
    this.StartDate = null;
    this.EndDate = null;
    this.LastReportDate = null;
    this.OrderNo = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetOrderReportIDByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteOrderReportID {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditOrderReportID {
  constructor() {
    this.ID = null;
    this.OrderID = null;
    this.OrderDetailsID = null;
    this.dsUser = null;
    this.dsAccounts = null;
    this.CampaignID = null;
    this.ReportTypeID = null;
    this.StartDate = null;
    this.EndDate = null;
  }
}
// Order Report
export class clsGetOrderReports {
  constructor() {
    this.ReportDate = null;
    this.ReportType = null;
    this.Clicks = null;
    this.Impressions = null;
    this.CTR = null;
    this.CPC = null;
    this.Cost = null;
    this.Certificate_Email = null;
    this.Certificate_Download = null;
    this.Appointment_Clicks = null;
    this.MailchimpDetails = null;
    this.LastSyncAt = null;
    this.Platform = null;
    this.OrderNo = null;
    this.LocationName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsDeleteOrderReport {
  constructor() {
    this.ID = null;
  }
}

// Orders
export class clsGetOrders {
  constructor() {
    this.OrderAt = null;
    this.OrderStatus = null;
    this.TotalAmount = null;
    this.OrderNo = null;
    this.LocationName = null;
    this.UserName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
export class clsGetOrderForDropdown {
  constructor() {
  }
}


// Notificaation
export class clsGetMyNotifications {
  constructor() {
    this.Description = null;
    this.NotificationAt = null;
    // this.TypeModule = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
//My Orders
export class clsGetMyOrders {
  constructor() {
    this.OrderNo = null;
    this.OrderDate = null;
    this.ProductCost = null;
    this.Name = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
// Forgot Password
export class clsForgotPassword {
  constructor() {
    this.EmailAddress = null;
  }
}
// ChangePassword
export class clsChangePassword {
  constructor() {
    this.Password = null;
    this.NewPassword = null;
  }
}
// SetPassword
export class clsSetPassword {
  constructor() {
    this.RequestID = null;
    this.Password = null;
  }
}

// My Orderdetails
export class clsGetOrderDetails {
  constructor() {
    this.ID = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}

export class clsGetDashboardLanding {
  constructor() {
    this.FromDate = null;
    this.ToDate = null;
    this.PracticeID = null;
    this.BrandID = null;
  }
}
export class clsGetPaidDashboard {
  constructor() {
    this.StartDate = null;
    this.EndDate = null;
    this.LocationID = null;
    this.BrandID = null;
  }
}
export class clsGetOrderListForDashboard {
  constructor() {
    this.OrderID = null;
    this.ShowAllOrders = null;
  }
}
export class clsGetOrganicDashboard {
  constructor() {
    this.StartDate = null;
    this.EndDate = null;
    this.LocationID = null;
    this.CategoryID = null;
  }
}
// contactUs
export class clsGetMyProfiles { }
// ApproveRejectLocation

export class clsApproveRejectLocation {
  constructor() {
    this.LocationID = null;
    this.TerritoryID = null;
    this.IsApproved = null;
    this.RejectionReason = null;
  }
}

// Impersonations
export class clsGetImpersonations {
  constructor() {
    this.Name = null;
    this.SequenceNo = null;
    this.CreatedAt = null;
    this.LastSyncAt = null;
    this.UserName = null;
    this.LocationName = null;
    this.CreatedByName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
export class clsGetImpersonationByID {
  constructor() {
    this.ID = null;
  }
}

export class clsDeleteImpersonation {
  constructor() {
    this.ID = null;
  }
}

export class clsAddEditImpersonation {
  constructor() {
    this.ID = null;
    this.UserID = null;
    this.Name = null;
    this.LocationID = null;
    this.SequenceNo = null;
  }
}

// ImpersonationLoginLogs
export class clsGetImpersonationLoginLogs {
  constructor() {
    this.LoginAt = null;
    this.LoginFromIP = null;
    this.TypeID = null;
    this.FromUserName = null;
    this.ImpersonationName = null;
    this.ToUserName = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
// SearchLocations
export class clsSearchLocations {
  constructor() {
    this.Name = null;
  }
}

// Get My Groups
export class clsGetMyGroups {
  constructor() { }
}

// ImpersonateMe
export class clsImpersonateMe {
  constructor() {
    this.LocationID = null;
  }
}

// DepersonateMe
export class clsDepersonateMe {
  constructor() {
    this.I = null;
  }
}

// GetMyGroupDetails
export class clsGetMyGroupDetails {
  constructor() {
    this.ID = null;
  }
}
// UpdateVeevaCode
export class clsUpdateVeevaCode {
  constructor() {
    this.ID = null;
    this.VeevaCode = null;
  }
}

// FreeAssetsReport
export class clsFreeAssetsReport {
  constructor() {
    this.Category = null;
    this.Name = null;
    this.Memberships = null;
    this.VeevaCode = null;
    this.ExpirationDate = null;
    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
export class clsAssetSummaryReport {
  constructor() {
    this.Area = null;
    this.Region = null;
    this.Territory = null;
    this.VTM = null;
    this.FromDate = null;
    this.ToDate = null;

    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
export class clsRegistrationReport {
  constructor() {
    this.RegistrationDate = null;
    this.ShipToNo = null;
    this.LocationName = null;
    this.Address1 = null;
    this.Address2 = null;
    this.City = null;
    this.State = null;
    this.Postcode = null;
    this.PhoneNo = null;
    this.Website = null;
    this.AppointmentURL = null;
    this.FullName = null;
    this.EmailAddress = null;
    this.VTM_Territory = null;
    this.VTM_Name = null;
    this.LoyaltyAccount = null;
    this.Memberships = null;

    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}
// EmailMarketings
export class clsAssetDownloadReport {
  constructor() {
    this.DownloadedAt = null;
    this.ShipToNo = null;
    this.CampaignName = null;
    this.BrandName = null;
    this.PracticeName = null;
    this.CampaignTheme = null;
    this.EmailAddress = null;
    this.VeevaCode = null;
    this.Version = null;
    this.VTM_Territory = null;
    this.VTM = null;
    this.FullName = null;
    this.AssetCategory = null;
    this.CSRFirstName = null;
    this.CSRLastName = null;
    this.Memberships = null;
    this.Address1 = null;
    this.Address2 = null;
    this.City = null;
    this.State = null;
    this.Postcode = null;
    this.PhoneNo = null;
    this.Website = null;
    this.AppointmentURL = null;
    this.ContactName = null;
    this.EmailAddress = null;
    this.SubscriptionNo = null;
    this.Subscription = null;
    this.StartDate = null;
    this.EndDate = null;
    this.NetPracticeCost = null;
    this.CoOp = null;
    this.OrderNo = null;
    this.Budget = null;
    this.Duration = null;
    this.TotalBudget = null;
    this.ReportTypeID = null;

    this.CurrentPage = 1;
    this.RecordPerPage = 25;
    this.SortExpression = null;
    this.SortDirection = null;
  }
}


export class clsBulkImportAddressBook_SN {
  constructor() {
    this.JSON = null;
  }
}

import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { URLs } from "../API/URLs";
import { jwtDecode } from "jwt-decode";

var CryptoJS = require("crypto-js");

const defaultDateFormats = {
  DDMMYYYY: "DD/MM/YYYY",
  MMDDYYYY: "MM/DD/YYYY",
  MMDDYY: "MMDDYY",
  MM_DD_YYYY: "MM-DD-YYYY",
  FormatDate: "DD-MMM-YY",
  FormatDateCMMS: "DD/MM/YYYY",
  SingleDatePiker: "YYYY-MM-DD",
};

function GetDateWithMyFormat(date) {
  return moment(date).format(defaultDateFormats.FormatDate);
}

function GetCMMSDateWithMyFormat(date) {
  return moment(date).format(defaultDateFormats.FormatDateCMMS);
}

function GetDateWithCommonFormat(date) {
  return moment(date).format(defaultDateFormats.MMDDYYYY);
}
function GetDateWithMM_DD_YYYY(date) {
  return moment(date).format(defaultDateFormats.MM_DD_YYYY);
}
function GetDateWithSingleDatePiker(date) {
  return moment(date).format(defaultDateFormats.SingleDatePiker);
}
function GetDateWithMM_DD_YY(date) {
  return moment(date).format(defaultDateFormats.MMDDYY);
}
function ConvertToDateMMDDYYYY(dateString) {
  const parts = dateString.split("-");
  const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
  return formattedDate;
}

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const GetMonthNumber = (monthName) => {
  const monthIndex = monthNames.indexOf(monthName);
  if (monthIndex === -1) {
    return monthName;
  }
  return monthIndex + 1; // Adding 1 because indexOf returns 0-based index
};

const DefaultLatLng = {
  lat: 40.21765,
  lng: -96.92733,
  IsLocationVerified: false,
};

const Constanttitle = {
  name: "Cobrand",
  desc: "Cobrand - React.js",
  Title1: "WELCOME TO BAUSCH + LOMB REACH",
  Title2:
    "A practice marketing platform with tools to help you reach patients through innovative technology",
  OrderDetails: "Order Details",
  ProductCost: "Product Cost",
  Producttitle: "Email Marketing:",
  ProductSubtitle: "Customize, Copy, Send.",
  Ordertitle: "My Orders.",
  CostEstimates: "Annual Supply Calculator",
  Ordersubtitle: "View Details.",
  CustomPromotitle: "Custom Promo Pages.",
  CustomPromosubtitle: "Edit and Manage",
  LocalAdtitle: "Local Ad Campaigns.",
  LocalAdsubtitle: "Customized for Your Practice.",
  Producttitle: "Email Marketing:",
  Hometitle: "WELCOME TO REACH",
  Homeleft: "Practice Marketing Services. ",
  HomeRight: "Targeted Campaigns for Maximum Engagement.",
  HomeDownLeft: " Practice Support Assets.",
  HomeDownright: " Elevate Efficiency, Enhance Practice Impact.",
  Featuredtitle: "Select All: set Featured Pratice Info For Your Campaign(s)",
  Featuredlefttitle: "Featured Pratice Logo",
  PracticeLogo: "Practice Logo",
  Practicetitle: "Practice Information",
  UserProfile: "User Profile",
  Annualsupplytitle: "Annual Supply Calculator Profile",
  CostToolProfile: "Cost Tool Profile",
  Annualsupplyrighttext:
    "Enter your national pricing for each Bausch + Lomb lens brand and indication.Click <b className='annualsupply-bold' >CONFIRM.</b> Prices will auto-populate in the Annual Supply Cost Calculator on the top menu.You may update pricing anytime.",
  Annualsupplycalculatertitle: "Annual Supply Calculator.",
  Annualsupplycalculaterheading:
    "Here`s What Your Bausch + Lomb Contact Lenses Will Cost",
  Dirctmailtitle: "Direct Mail & Print.",
  Dirctmailsubtitle: "Customize, Print, or Download.",
  Changepassword: "Change Password",
  Mediatitle: "Media Library.",
  Mediasubtitle: "Upload, Favorite, and Select from Stock images.",
  Cart: "Your Cart",
  CopiedClipboard: "Content Copied to Clipboard",
  PrivacyPolicysubtitle: "COBRAND MEDIA ONLINE TERMS AND CONDITIONS",
  clinicregistrationcardtitle1: "Practice Info",
  clinicregistrationcardtitle2: "Member Ships",
  clinicregistrationcardtitle3: "Additional Info",
  clinicregistrationcardtitle4: "Practice Logo",
  rightimginformation1: "-Accpeted Files : PNG & JPG",
  rightimginformation2: "-PNG with transparent background recommended",
  rightimginformation3: "-Recommended logo size: 1050 pixels X 750 pixels",
  rightimginformation4: "-Smaller logos may appear pixelated in print products",
  VsMember: "Vision Sources Member Practice",
  VSPMember: "VSP Premier Member Practice",
  registrationaddanotherlocation:
    "Do you want to add another location to this account?",
  registrationtrems: "Please confirm that you agree to the terms & conditions.",
  registrationpracticetext:
    "• Accepted files: PNG & JPG • Recommended logo size: 1050 pixels X 750 pixels • Smaller logos may appear pixelated in print products",
  addsocialpostAI1:
    "To utilize our AI tool for composing your social media posts, simply provide a description of the content you desire here",
  addsocialpostAI2:
    "Alternatively, you can directly input your desired post copy into the designated 'Post Copy' text box to the left. Please ensure clarity and specificity in your description for optimal AI-generated results.",
  addsocialpostNote:
    "Note: For Bausch + Lomb posts,please use content from our library. Competitive brand posts may not be added to your celender.",
  addsocialpostaddpost:
    "Select your social platform(s), post date and time then click CONFIRM to add your post to the calendar.",
  featurepracticefirstparagraph:
    "Enter the featured location information you'd like to showcase in your marketing campaign(s). Opting include ALL locations trigger the automatic generation of a custom doc finder page. This doc finder function as a centralized promotional site directly linked to your campaign(s), seamlessly connecting consumers to your practices and offers.",
  featurepracticeverifytext:
    "Verify your facebook and/or Instagram credentials below for REACH to post approved Bausch + Lomb content on your social bussiness pages. Credential verification is done directly with Facebook and Instagram; REACH does not store or access these credentials.",
  CustomPostFeedPagetitle: "Custom Post Feeds",
  Artworkinfopagetitle: "ARTWORK INFO",
  CampainOptiontitle: "Campaign Options",
  LocationNewSocial:
    "Select a location to enter/update your social media credentials",
  LocationNew:
    "Select the Location(s) you would like to include in your campaign then click the Next Button.",
  locationNewTitle: "Use Bausch + Lomb recommended targeting",
  MarketerLocationsubTitle: "Select a Group to Login.",
  SimplyNotedTitle: "Simply Noted.",
  SimplyNotedSubTitle: "Create & Send Real Handwritten Letters.",
};

const NumberValidation = (event) => {
  const input = event.target.value;
  // Remove non-numeric characters
  const cleanedInput = input.replace(/\D/g, "");
  // Limit input to 10 digits

  let formattedPhoneNumber = "";
  if (cleanedInput.length <= 10) {
    formattedPhoneNumber = cleanedInput.replace(
      /^(\d{0,3})(\d{0,3})(\d{0,4})$/,
      "($1) $2-$3"
    );
  } else {
    formattedPhoneNumber = cleanedInput
      .slice(0, 10)
      .replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/, "($1) $2-$3");
  }
  return formattedPhoneNumber;
};

export const RoleIDWithName = {
  ADMIN: '43686D1F-4FBD-4173-B744-2B2CC07C5B05',
  SUPERADMIN: 'A215777F-BB24-4829-9621-16731AAAB760',
  VTM: '5C203C1A-2AE9-4211-B49E-347C73FDAE51',
  MARKETER: 'B6423F64-00A8-4C02-AAC8-57246575D124',
  PRACTICE: 'A560E1FF-E7C9-47B0-AF6C-642B28D9548A',
  DUMMY: '9D0E3847-A654-4FEA-9157-B7383E746AA5',

}

const NumberInput = (event) => {
  const input = event.target.value;
  const cleanedInput = input.replace(/\D/g, "");
  return cleanedInput
}

export const NumberInputForZipSearch = (event) => {
  const cleanedInput = event.replace(/\D/g, "");
  return cleanedInput
}

const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "" : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

// const validateEmail = (email) => {
//   const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//   return regex.test(email);
// }
const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
  return re.test(String(email).toLowerCase());
}

const isValidURL = (url) => {
  const pattern = new RegExp(
    /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.(com|in|org|net|edu)/
  ); // fragment locator
  return pattern.test(url);
};
const websiteURLFromat = (url) => {
  var websiteFromat = url.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return websiteFromat;
};

const NumberDigitValidation = (event, digit) => {
  const input = event.target.value;
  // Remove non-numeric characters
  // const cleanedInput = input.replace(/\D/g, '');
  const cleanedInput = input.replace(/[^\d.]/g, "");
  // Limit input to 10 digits

  const truncatedInput = cleanedInput.slice(0, digit);
  return truncatedInput;
};

const SearchSuggestions = (value, suggestionData) => {
  const mockSuggestions = suggestionData;

  // Filter suggestions based on the input value
  const filteredSuggestions = mockSuggestions.filter((suggestion) =>
    suggestion.toLowerCase().includes(value.toLowerCase())
  );

  return filteredSuggestions;
};

const AppConstant = {
  StickyColor: "#fff",
  Pagination: [10, 25, 50, 100, 200, 500, 1000],
  Pagination2: [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
    { value: 500, label: '500' },
    { value: 1000, label: '1000' },
    { value: 100000, label: 'All' },

  ],
  DefaultSort: "DESC",
  SortDirection: "ASC",
  DefaultRecordsPerPage: 25,
};

const chipData = [
  { color: "#a94442", backgroundColor: "#f2dede", value: "NO", name: "NO", width: "60px", },
  { color: "#3c763d", backgroundColor: "#dff0d8", value: "YES", name: "YES", width: "60px", },
  { color: "#a94442", backgroundColor: "#f2dede", value: "No Select", name: "No Select", width: "100px", },
  { color: "#3c763d", backgroundColor: "#dff0d8", value: "Single Select", name: "Single Select", width: "100px", },
  { color: "#3c763d", backgroundColor: "#dff0d8", value: "Multi Select", name: "Multi Select", width: "100px", },
  { color: "#0a34b1b8", backgroundColor: "#1237df4a", value: "Pending Approval", name: "Pending", width: "100px", },
  { color: "#3c763d", backgroundColor: "#dff0d8", value: "Approved", name: "Approved", width: "100px", },
  { color: "#a94442", backgroundColor: "#f2dede", value: "Rejected", name: "Rejected", width: "100px", },
  { color: "#1a1110c7", backgroundColor: "", value: "WorkOrders", name: "WorkOrders", width: "60px", },
  { color: "#20b756", backgroundColor: "#12df2942", value: "Created", name: "Created", width: "100%", },
  { color: "#a94442", backgroundColor: "#f2dede", value: "Cancelled", name: "Cancelled", width: "100%", },
  { color: "#6D225C", backgroundColor: "#DD92CB", value: "Started", name: "Started", width: "100%", },
  { color: "#0a34b1b8", backgroundColor: "#1237df4a", value: "Paused", name: "Paused", width: "100%", },

  { color: "#20b756", backgroundColor: "#12df2942", value: "1", name: "Created", width: "100%", },
  { color: "#d582a4", backgroundColor: "#df12ac4a", value: "2", name: "Acknowledged", width: "100%", },
  { color: "#0a34b1b8", backgroundColor: "#1237df4a", value: "3", name: "Assigned", width: "100%", },
  { color: "#6D225C", backgroundColor: "#DD92CB", value: "4", name: "Started", width: "100%", },
  { color: "#3c763d", backgroundColor: "#dff0d8", value: "5", name: "Completed", width: "100%", },
  { color: "#bf762a", backgroundColor: "#ffa2337a", value: "6", name: "Closed", width: "100%", },
  { color: "#a94442", backgroundColor: "#f2dede", value: "7", name: "Cancelled", width: "100%", },
  { color: "", backgroundColor: "", value: "Sender", name: "Sender", width: "80px", },
  { color: "", backgroundColor: "", value: "Recipient", name: "Recipient", width: "80px", },
  { color: "black", backgroundColor: "#80808099", value: "" },];

const IsEmpty = (data) => {
  return (
    data == null ||
    data.length === 0 ||
    data == undefined ||
    data == "" ||
    data == "0"
  );
};

const isEmptyObject = (data) => {
  return Object.keys(data).length === 0 && data.constructor === Object;
};

// Social Automation 1
// Local Ad 2
// Custom Promo (Landing page) 3
// Email Marketing 4
// Direct Mail 5
// Free Assets 6

const HomeScreenNavigate = (ModuleNo) => {
  switch (ModuleNo) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return "/productselection";
    case 6:
      // case 9:
      return "/LocalAd";
    default:
      return "/home";
  }
};

// Navigate, Customize 1
// Preview popup 2

const GetButtons = (ID) => {
  switch (ID) {
    case 1:
    case 7:
    case 8:
    case 9:
    case 10:
      return [{ Name: "View", Action: 2 }];
    case 2:
      return [
        { Name: "Order", Action: 1 },
        { Name: "Preview", Action: 2 },
      ];
    case 3:
      return [
        { Name: "Download", Action: 5 },
        { Name: "Copy Text", Action: 6 },
      ];
    case 4:
    case 5:
      return [
        { Name: "Customize", Action: 1 },
        { Name: "Preview", Action: 2 },
      ];
    case 6:
      return [
        // { Name: "Single Card", Action: 7 },
        { Name: "View Post", Action: 3 },
        { Name: "+ Calendar", Action: 4 },
      ];
    case 11:
      return [
        // { Name: "Single Card", Action: 7 },
        { Name: "Single Card", Action: 7 },
        { Name: "Bulk Parches", Action: 7 },
      ];
    default:
      return [];
  }
};

const ProductScreenNavigate = (ModuleNo) => {
  switch (ModuleNo) {
    case 1:
      return "/locationnew";
    case 3:
      return "/my-landing-page";
    case 2:
    case 4:
    case 5:
    case 9:
    case 11:
      return "/LocalAd";

    default:
      return "/home";
  }
};

// Social Media Ads	1
// Display Ads	2
// Doc Finder	3
// Promo Page	4
// Free Trial	5
// Email Marketing	6
// Postcard Front	7
// Postcard Back	8
// Social Automation	9
// Free Assets	10

const DOCUMENTS = [
  {
    title: "Upload Front Image",
    SequenceNo: 0,
    DocumentName: "",
    DocumentData: "",
    DocumentURL: "",
    Sync: 0,
  },
];
const FileExtension = {
  TypeCSV: ".csv",
  TypeSVG: ".svg",
  TypeZIP: ".zip",
  TypeHTML: ".html",
  TypeMP4: ".mp4",
  TypePNG: ".png",
  TypeJPG: ".jpg",
  TypeJPEG: ".jpeg",
  TypePDF: ".pdf",
  TypeGIF: ".gif",
};

const TableColor = { main: "red", buttonFontColor: "#ffff" };
const DocumentTypes = {
  Category: 1,
  ProductTile: 2,
  ProductTileHover: 3,
  ProductDescription: 4,
  ProductImages: 5,
  ProductAssets: 6,
  ProductAssetPreview: 7,
  ProductSubCategoryAsset: 8,
  ProductSubCategoryAssetPreview: 9,
  LocationLogo: 10,
  LocationPhoto: 11,
  CalendarAsset: 12,
  CustomAsset: 13,
  WelcomeScreenPopup: 14,
  ScreenBanner: 15,
  OrderDetailsLogo: 16,
  SupplyToolBanner: 17,
  SupplyToolFooter: 18,
  SupplyToolLead: 19,
  FaviconIcon: 20,
  LoginScreenImage: 21,
  LoginHeaderLogo: 22,
  LoginFooterLogo: 23,
  HeaderLogo: 24,
  FooterLogo: 25,
  CalendarAssetPreview: 26,
  MediaLibrary: 27,
  CustomPostEmailAttachment: 28,
  LoginHeaderBar: 29,
  ProfilePic: 30,
  HTML: 31,
};

const facebookConfig = {
  client_id: "584550876158294", // Live
  fields: "email,picture",
  fbscope: "public_profile,pages_show_list,pages_manage_posts",
  instascope: "public_profile,instagram_basic,instagram_content_publish",
};

const GetLatLongFromAddress = (address) => {
  const apiKey = URLs.googlekey; // Replace with your actual API key
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`;
  let latitude = "";
  let longitude = "";
  fetch(url)
    .then((response) => response.json())
    .then((data) => {
      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        latitude = location.lat;
        longitude = location.lng;
        return { latitude, longitude };
      } else {
        console.error("Error:", data.status);
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

const DemoCoords = [
  { lat: 29.047487, lng: 41.023164 },
  { lat: 29.0459633, lng: 41.0212904 },
  { lat: 29.0449333, lng: 41.0167573 },
  { lat: 29.0393543, lng: 41.0106695 },
  { lat: 29.032917, lng: 41.0049697 },
  { lat: 29.0226173, lng: 41.0061356 },
  { lat: 29.0078545, lng: 41.0039334 },
  { lat: 29.0201283, lng: 40.9765933 },
  { lat: 29.0319729, lng: 40.9657708 },
  { lat: 29.0784073, lng: 40.9536501 },
  { lat: 29.0944576, lng: 40.9493068 },
  { lat: 29.0975475, lng: 40.9514461 },
  { lat: 29.1052294, lng: 40.9647986 },
  { lat: 29.097338, lng: 40.978242 },
  { lat: 29.0931273, lng: 40.9835914 },
  { lat: 29.0858746, lng: 40.987738 },
  { lat: 29.056509, lng: 40.998902 },
  { lat: 29.061456, lng: 41.008443 },
  { lat: 29.0617561, lng: 41.0104752 },
  { lat: 29.0595245, lng: 41.0126772 },
  { lat: 29.052014, lng: 41.018198 },
  { lat: 29.047487, lng: 41.023164 },
];

const secretKey = "TC2024KN";

const storageKeys = {
  TenantConfiguration: "T-C",
  IsLoged: "I-L",
  UserID: "U-ID",
  RoleID: "R-ID",
  RoleName: "R-N",
  UserName: "U-N",
  PracticeName: "P-N",
  ProductID: "P-D",
  CategoryID: "C-D",
  ProductCategoryID: "PC-ID",
  ProductCategorySubCategoryID: "PCS-ID",
  SubscriptionID: "SCR-ID",
  LocationID: "LC-ID",
  LocationIDs: "LC-IDs",
  FacebookUserID: "FBU-ID",
  FBPageData: "FBP-D",
  IGPageData: "IGP-D",
  IGAccessToken: "IG-AT",
  MySubscriptions: "M-SUB",
  MySubscriptionsList: "M-SUBL",
  ProductIDByNav: "P-ID",
  AdminNav: "A-N",
  CartCount: "C-C",
  NotificationsData: "N_D",
  MarketerGroupID: "MG-ID",
  MarketerID: "M-ID",
  AIGeneratePostCopy: "AI-GPC",
  JwtDicoder: "JWT-DI",
  SimplyBtnAction: "SB-A",
  CID: "CI-D",
};

const addItemStore = (key, value) => {
  const cipherText = CryptoJS.AES.encrypt(value, secretKey).toString();
  let newObj = { key: key, value: cipherText };
  let initialvalue = !IsEmpty(localStorage.getItem("p-all"))
    ? JSON.parse(localStorage.getItem("p-all"))
    : [];
  if (!IsEmpty(initialvalue)) {
    let filtervalue = initialvalue.filter((item) => {
      return item.key == key;
    });
    if (!IsEmpty(filtervalue)) {
      const updatedArray = initialvalue.map((item) => {
        return item.key == key ? { ...item, value: cipherText } : item;
      });
      localStorage.setItem("p-all", JSON.stringify(updatedArray));
    } else {
      let updatedValue = [...initialvalue, newObj];
      localStorage.setItem("p-all", JSON.stringify(updatedValue));
    }
  } else {
    let updatedValue = [...initialvalue, newObj];
    localStorage.setItem("p-all", JSON.stringify(updatedValue));
  }
};

const updateItemStore = (key) => {
  localStorage.setItem("");
};

const getItemStore = (key) => {
  let data = JSON.parse(localStorage.getItem("p-all"));
  if (!IsEmpty(data)) {
    let filtervalue = data.filter((item) => {
      return item.key == key;
    });
    return !IsEmpty(filtervalue) ? decryptData(filtervalue[0].value) : null;
  }
};

const decryptData = (value) => {
  const bytes = CryptoJS.AES.decrypt(value, secretKey);
  const data = bytes.toString(CryptoJS.enc.Utf8);
  return data;
};

const removeItemStore = (key) => {
  localStorage.removeItem(key);
};
const clearStore = () => {
  localStorage.clear();
};

/**
 * function to check if user is logged in or not
 */
const checkLogin = () => {
  if (sessionStorage.authData) {
    return true;
  } else {
    return false;
  }
};

/**
 * function to set user authentication data
 */
const setAuthData = (data) => {
  const cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey);
  sessionStorage.setItem("authData", cipherText.toString());
};

/**
 * function to get user authentication data
 */
const getAuthData = () => {
  const data = sessionStorage.authData;
  if (data) {
    const bytes = CryptoJS.AES.decrypt(data.toString(), secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } else {
    return {};
  }
};

/**
 * function to remove user authentication data
 */
const removeAuthData = () => {
  sessionStorage.removeItem("authData");
};

const authService = {
  checkLogin: checkLogin,
  // getAccessToken: getAccessToken,
  // getUserRole: getUserRole,
  // getUserId: getUserId,
  setAuthData: setAuthData,
  getAuthData: getAuthData,
  removeAuthData: removeAuthData,
};

const cnvertImageToURL = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const blob = await response.blob();
    const fileExtension = blob.type.split('/')[1]; // Extract file extension from MIME type
    const UUID = uuidv4();
    const fileName = `${UUID}.${fileExtension}`;

    const file = new File([blob], fileName, { type: blob.type });

    return {
      DocumentData: file,
      DocumentName: fileName,
    };
  } catch (error) {
    console.error('Error in CnvertImageToURL:', error.message);
    throw error; // Rethrow the error to be handled by the caller
  }
};


function DownloadFileClientside(Name, response) {
  const href = window.URL.createObjectURL(response);
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", Name); //or any other extension
  document.body.appendChild(link);
  link.click();
}

const handleLogout = () => {
  authService.removeAuthData();
  localStorage.clear();
  sessionStorage.clear();
};

const handleLogin = (response) => {
  const decodedHeader = jwtDecode(response.jwt, { header: false });
  addItemStore(
    storageKeys.RoleName,
    decodedHeader.D == "/Home"
      ? "0"
      : decodedHeader.D == "/GroupHome"
        ? "2"
        : "1"
  );
  localStorage.setItem("Access_Token", response.access_token);
  localStorage.setItem("UID", decodedHeader.ID);
  addItemStore(storageKeys.UserID, decodedHeader.ID);
  addItemStore(storageKeys.UserName, decodedHeader.FullName);
  addItemStore(storageKeys.PracticeName, decodedHeader.L);
  addItemStore(storageKeys.MarketerID, decodedHeader.I);
  addItemStore(storageKeys.SubscriptionID, "0");
};

const replacePlaceholders = (data, LocationData, CalenderData) => {
  return data.map((item) => {
    if (item.type === "subdropdown" && item.DropdownOption) {
      item.DropdownOption = item.DropdownOption.map((subItem) => {
        if (subItem.submenuOption === "@Calendar") {
          subItem.submenuOption = CalenderData;
        } else if (subItem.submenuOption === "@Location") {
          subItem.submenuOption = LocationData;
        }
        return subItem;
      });
    }
    return item;
  });
};

export {
  addItemStore,
  AppConstant,
  authService,
  chipData,
  clearStore,
  cnvertImageToURL,
  Constanttitle,
  ConvertToDateMMDDYYYY,
  defaultDateFormats,
  DefaultLatLng,
  DemoCoords,
  DOCUMENTS,
  DocumentTypes,
  DownloadFileClientside,
  facebookConfig,
  FileExtension,
  formatPhoneNumber,
  GetButtons,
  GetCMMSDateWithMyFormat,
  GetDateWithCommonFormat,
  GetDateWithMM_DD_YYYY,
  GetDateWithMM_DD_YY,
  GetDateWithMyFormat,
  GetDateWithSingleDatePiker,
  getItemStore,
  GetLatLongFromAddress,
  GetMonthNumber,
  HomeScreenNavigate,
  IsEmpty,
  isEmptyObject,
  isValidURL,
  monthNames,
  NumberDigitValidation,
  NumberValidation,
  ProductScreenNavigate,
  removeItemStore,
  SearchSuggestions,
  storageKeys,
  TableColor,
  updateItemStore,
  websiteURLFromat,
  handleLogout,
  handleLogin,
  replacePlaceholders,
  validateEmail,
  NumberInput,
};

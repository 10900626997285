import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { BaseColor, BaseImages, FontFamily } from "./source/config";
import {
  APILogs,
  AdminHome,
  AdminUrlLink,
  AnalyticsDashboard,
  AnnualSupplyCalculater,
  Areas,
  AssetSizes,
  AssetTypeAssetSizes,
  AssetTypes,
  AutomationCalender,
  AutomationCalenderNew,
  BL_ST_Configurations,
  BL_ST_Leads,
  BL_ST_LensTypes,
  Banners,
  BlankPage,
  BugTracker,
  CalendarAssets,
  CampaignOrder,
  Cart,
  Categories,
  Cities,
  Registration,
  CommunicationConfiguration,
  PayPalSubscriptionPlans,
  Impersonation,
  SimplyNoted,
  // Communication
  CommunicationTemplates,
  Communications,
  ContactUs,
  ContestSummary,
  CostEstimates,
  Countries,
  CustomPromo,
  // Subscriptions
  CustomePostFeeds,
  Departments,
  DocumentTypes,
  Documents,
  EmailMarketings,
  Filter,
  FormCantrol,
  FreeAssets,
  GlobalActions,
  GlobalAssetSizes,
  GlobalAssetTypes,
  GlobalFilters,
  GlobalModuleTabs,
  GlobalModules,
  GlobalSubModules,
  HTMLEmailDemo,
  HomeScreen,
  // Misc
  KeyWordChecked,
  LPReports,
  LPTemplates,
  LandingpageDashboard,
  ListUser,
  LocalAd,
  LocalAdCampaign,
  Location,
  LocationMemberships,
  LocationNew,
  Locations,
  Login,
  LoginLogs,
  MediaLibrary,
  Memberships,
  MixScreen,
  Modules,
  MyOrder,
  MyOrderDetails,
  Positions,
  PostSchedule,
  PostScheduleHistory,
  PostalCharges,
  PracticeRegistrationAll,
  Practices,
  PrivacyPolicy,
  ProductAssets,
  ProductCategories,
  ProductActions,
  ProductCategorySubCategories,
  ProductFilters,
  ProductFiltersDetails,
  ProductSelection,
  Products,
  Regions,
  Registrations,
  Leads,
  // ACS
  Rights,
  Roles,
  ScreenLayout,
  Screens,
  Segments,
  Setpassword,
  // configration
  SiteConfigurations,
  SocialAutomation,
  SocialPost,
  States,
  SubModuleAssetTypes,
  SubModules,
  SubscriptionDetails,
  Subscriptions,
  TenantConfigurations,
  // SN
  Styles,
  GiftCards,
  Addressbook,
  Templates,
  // LN
  LNTemplates,
  HTMLPreview,
  LNCampaign,
  Campaignform,
  LNCampaignHistory,
  CampaignLocation,
  CampaignDetailsTab,
  // tenants
  TenantDomains,
  TenantSMTP,
  Tenants,
  TermsOfUse,
  Territories,
  Thankyou,
  UserLocations,
  UserLoginLogs,
  UserTerritories,
  UserTypes,
  Users,
  ViewAutomationcalenders,
  WelcomePopup,
  Zipcodes,
  ChatGPTtransactions,
  LPReportDetails,
  LocationDetails,
  AccessHistory,
  LandingPages,
  LPHistory,
  EmailLogs,
  Information,
  LocationMaster,
  OrderReportIDs,
  OrderReports,
  Orders,
  ViewNotifications,
  ImpersonationLoginLogs, EmailBuilder,
  //ADMIN USERS
  AdminUsers,
  MarketerLogin,
  MarketerLocations,
  LocationFilter,
  AddressBook,
  TemplateStyle,
  NewAddress,
  // Reports
  FreeAssetsReports,
  AssetDownload,
} from "./source/config/PagePathList";
import {
  IsEmpty,
  addItemStore,
  authService,
  getItemStore,
  storageKeys,
} from "./source/config/appConstant";
import { clsGetTenantConfiguration } from "./source/API/APIParameters";
import * as APIController from "./source/API/APIController";
import { CommonLoader } from "./source/components";

function App() {
  const [loading, setloading] = useState(true);
  const isLoggedIn = authService.checkLogin();

  useEffect(() => {
    // Update CSS variables with JavaScript  // For Global Colors And Images Call From Api as well as appconfig file
    Object.entries(BaseImages).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}`, value);
    });
    Object.entries(FontFamily).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}`, value);
    });
    Object.entries(BaseColor).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}`, value);
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn === false) {
      WsGetTenantConfiguration();
    }
  }, [isLoggedIn]);
  const WsGetTenantConfiguration = (response) => {
    setloading(true);
    const objParameter = new clsGetTenantConfiguration();
    APIController.GetTenantConfiguration(objParameter).then((response) => {
      if (response.error == null) {
        if (!IsEmpty(response.data)) {
          localStorage.setItem("pageTitle", response.data[0].a);
          localStorage.setItem("faviconUrl", response.data[0].l);
          addItemStore(
            storageKeys.TenantConfiguration,
            JSON.stringify(response.data)
          );
          setloading(false);
        }
      }
    });
  };

  if (isLoggedIn && getItemStore(storageKeys.RoleName) == "0") {
    return (
      <div>
        <ScreenLayout>
          <Routes>
            <Route path="/mixScreen" element={<MixScreen />} />
            <Route path="/emailbuilder" element={<EmailBuilder />} />
            <Route path="/blankpage" element={<BlankPage />} />
            <Route path="/formcantrol" element={<FormCantrol />} />
            <Route path="/home" element={<HomeScreen />} />
            <Route path="/productselection" element={<ProductSelection />} />
            <Route path="/LocalAd" element={<LocalAd />} />
            <Route path="/location" element={<Location />} />
            <Route path="/locationnew" element={<LocationNew />} />
            <Route path="/AutomationCalender" element={<AutomationCalender />} />
            <Route path="/AutomationCalendernew" element={<AutomationCalenderNew />} />
            <Route path="/Localadcampaign" element={<LocalAdCampaign />} />
            <Route path="/my-landing-page" element={<CustomPromo />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/myorder
            " element={<MyOrder />} />
            <Route path="/myOrderdetails" element={<MyOrderDetails />} />
            <Route path="/AnnualSupplyCalculater" element={<AnnualSupplyCalculater />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/termsofuse" element={<TermsOfUse />} />
            <Route path="/medialibrary" element={<MediaLibrary />} />
            <Route path="/HTMLEmailDemo" element={<HTMLEmailDemo />} />
            <Route path="/CostEstimates" element={<CostEstimates />} />
            <Route path="/SubscriptionDetails" element={<SubscriptionDetails />} />
            <Route path="/thankyou" element={<Thankyou />} />
            <Route path="/SimplyNoted" element={<SimplyNoted />} />

            <Route path="/addressbook" element={<AddressBook />} />
            <Route path="/templatestyle" element={<TemplateStyle />} />
            <Route path="/newaddress" element={<NewAddress />} />

            {/* <Route path="/practiceinformation" element={<PracticeInformation />} /> */}
            {/* <Route path="/featuredpratice" element={<FeaturedPratice />} /> */}
            {/* <Route path="/annualsupply" element={<AnnualSupply />} /> */}
            {/* <Route path="/locationselection" element={<LocationSelection />} /> */}
            {/* <Route path="/viewsocialpost" element={<ViewSocialPost />} /> */}
            {/* <Route path="/addsocialpost" element={<AddSocialPost />} /> */}
            {/* <Route path="/userprofile" element={<UserProfile />} /> */}
            {/* <Route path="/changepassword" element={<ChangePassword />} /> */}
            {/* <Route path="/sidemodal" element={<Sidemodal />} /> */}
            {/* <Route path="/artWorkInfo" element={<ArtWorkInfo />} /> */}
            {/* <Route path="/campainoption" element={<CampainOption />} /> */}
            {/* <Route path="/commonpopup" element={<CommonPopup />} /> */}
            {/* NOT USE */}
            {/* <Route path="/emailmarketing" element={<EmailMarketing />} /> */}
            {/* <Route path="/directmail" element={<DirectMail />} /> */}
            {/* <Route path="/viewpost" element={<ViewPost />} /> */}

            {/* Registrations */}
            <Route path="/registration/practices" element={<Practices />} />
            <Route path="/registration/users" element={<ListUser />} />

            {/* automation */}
            {/* <Route path="/automation/custompostfeeds" element={<CustomePostFeeds />} /> */}

            {/* Dashboard */}
            <Route path="/dashboard/AnalyticsDashboard" element={<AnalyticsDashboard />} />
            <Route path="/dashboard/LandingpageDashboard" element={<LandingpageDashboard />} />
            <Route path="/ViewNotifications" element={<ViewNotifications />} />

            <Route path="/adminurllink" element={<AdminUrlLink />} />
            <Route path="*" element={<Navigate replace to="/home" />} />
          </Routes>
        </ScreenLayout>
      </div>
    );
  } else if (isLoggedIn && getItemStore(storageKeys.RoleName) == "1") {
    return (
      <ScreenLayout>
        <Routes>
          {/* Admin Screen Routes Start */}
          <Route path="/adminhome" element={<AdminHome />} />

          {/* Super Admin */}
          {/* ACS */}
          <Route path="/Super/ACS/rights" element={<Rights />} />
          <Route path="/Super/ACS/roles" element={<Roles />} />
          <Route path="/Super/ACS/screens" element={<Screens />} />

          {/* BugTracker */}
          <Route path="/Super/Bugtracker/bugtracker" element={<BugTracker />} />

          {/* Tenant */}
          <Route path="/Super/Tenant/tenantdomains" element={<TenantDomains />} />
          <Route path="/Super/Tenant/tenants" element={<Tenants />} />
          <Route path="/Super/Tenant/tenantsmtp" element={<TenantSMTP />} />
          <Route path="/Super/Tenant/SiteConfigurations" element={<SiteConfigurations />} />
          <Route path="/Super/Tenant/TenantConfigurations" element={<TenantConfigurations />} />
          <Route path="/Super/Tenant/usertypes" element={<UserTypes />} />
          <Route path="/Super/Tenant/documenttypes" element={<DocumentTypes />} />
          <Route path="/Super/Tenant/documents" element={<Documents />} />
          <Route path="/Super/Tenant/APILogs" element={<APILogs />} />
          <Route path="/Super/Tenant/ChatGPTtransactions" element={<ChatGPTtransactions />} />

          {/* Communication */}
          <Route path="/Super/Communication/communicationtemplates" element={<CommunicationTemplates />} />
          <Route path="/Super/Communication/CommunicationConfiguration" element={<CommunicationConfiguration />} />
          <Route path="/Super/Communication/Communications" element={<Communications />} />

          {/* Global */}
          <Route path="/Super/Global/globalmodules" element={<GlobalModules />} />
          <Route path="/Super/Global/globalsubmodules" element={<GlobalSubModules />} />
          <Route path="/Super/Global/globalassettypes" element={<GlobalAssetTypes />} />
          <Route path="/Super/Global/globalassetsizes" element={<GlobalAssetSizes />} />
          <Route path="/Super/Global/globalfilters" element={<GlobalFilters />} />
          <Route path="/Super/Global/globalmoduletabs" element={<GlobalModuleTabs />} />
          <Route path="/Super/Global/globalactions" element={<GlobalActions />} />

          {/* USERS */}
          <Route path="/Super/users/adminusers" element={<AdminUsers />} />

          {/* Admin */}
          {/* Landing Pages */}
          <Route path="/Admin/LandingPages/Templates" element={<LPTemplates />} />
          <Route path="/Admin/LandingPages/LandingPages" element={<LandingPages />} />
          <Route path="/Admin/LandingPages/Leads" element={<BL_ST_Leads />} />
          <Route path="/Admin/LandingPages/Reports" element={<LPReports />} />

          {/* Supply Tool */}
          <Route path="/Admin/SupplyTool/LensTypes" element={<BL_ST_LensTypes />} />
          <Route path="/Admin/SupplyTool/Configurations" element={<BL_ST_Configurations />} />
          <Route path="/Admin/SupplyTool/Leads" element={<Leads />} />
          <Route path="/Admin/SupplyTool/AccessHistory" element={<AccessHistory />} />
          <Route path="/Admin/SupplyTool/LocationDetails" element={<LocationDetails />} />

          {/* Orders */}
          <Route path="/Admin/Orders/Orders" element={<Orders />} />
          <Route path="/Admin/Orders/OrderReportIDs" element={<OrderReportIDs />} />
          <Route path="/Admin/Orders/OrderReports" element={<OrderReports />} />

          {/* Locations */}
          <Route path="/Admin/Locations/locations" element={<Locations />} />
          <Route path="/Admin/Locations/Information" element={<Information />} />
          <Route path="/Admin/Locations/users" element={<Users />} />
          <Route path="/Admin/Locations/locationmaster" element={<LocationMaster />} />
          {/* <Route path="Admin/Locations/CoOp" element={<CoOp />} /> */}

          {/* Soical Automation */}
          <Route path="/Admin/SocialAutomation/calenders" element={<ViewAutomationcalenders />} />
          <Route path="/Admin/SocialAutomation/CalendarAssets" element={<CalendarAssets />} />
          <Route path="/Admin/SocialAutomation/CalendarNegativeKeywords" element={<KeyWordChecked />} />
          <Route path="/Admin/SocialAutomation/Subscriptions" element={<Subscriptions />} />
          <Route path="/Admin/SocialAutomation/PostSchedule" element={<PostSchedule />} />
          <Route path="/Admin/SocialAutomation/EmailLogs" element={<EmailLogs />} />
          <Route path="/Admin/SocialAutomation/SchedulerHistory" element={<PostScheduleHistory />} />
          <Route path="/Admin/SocialAutomation/custompostfeeds" element={<CustomePostFeeds />} />

          {/* Configurations */}
          <Route path="/Admin/Configurations/Modules" element={<Modules />} />
          <Route path="/Admin/Configurations/SubModules" element={<SubModules />} />
          <Route path="/Admin/Configurations/AssetTypes" element={<AssetTypes />} />
          <Route path="/Admin/Configurations/AssetSizes" element={<AssetSizes />} />
          <Route path="/Admin/Configurations/Filter" element={<Filter />} />
          <Route path="/Admin/Products/Products" element={<Products />} />

          {/* Reports */}
          <Route path="/Admin/reports/practiceregistrationall" element={<PracticeRegistrationAll />} />
          <Route path="/Admin/reports/contestsummary" element={<ContestSummary />} />
          <Route path="/Admin/reports/Registrations" element={<Registrations />} />
          <Route path="/Admin/reports/socialautomation" element={<SocialAutomation />} />
          <Route path="/Admin/reports/campaignorder" element={<CampaignOrder />} />
          <Route path="/Admin/reports/emailmarketings" element={<EmailMarketings />} />
          <Route path="/Admin/reports/loginlogs" element={<LoginLogs />} />
          <Route path="/Admin/reports/freeassets" element={<FreeAssets />} />
          <Route path="/Admin/reports/ImpersonationLoginLogs" element={<ImpersonationLoginLogs />} />
          <Route path="/Admin/reports/FreeAssetsReports" element={<FreeAssetsReports />} />
          <Route path="/Admin/reports/EmailMarketings" element={<EmailMarketings />} />
          <Route path="/Admin/reports/AssetDownload" element={<AssetDownload />} />

          {/* Master */}
          <Route path="/Admin/Master/Areas" element={<Areas />} />
          <Route path="/Admin/Master/Regions" element={<Regions />} />
          <Route path="/Admin/Master/Territories" element={<Territories />} />
          <Route path="/Admin/Master/Countries" element={<Countries />} />
          <Route path="/Admin/Master/Cities" element={<Cities />} />
          <Route path="/Admin/Master/States" element={<States />} />
          <Route path="/Admin/Master/Zipcodes" element={<Zipcodes />} />
          <Route path="/Admin/Master/Categories" element={<Categories />} />
          <Route path="/Admin/Master/Memberships" element={<Memberships />} />
          <Route
            path="/Admin/Master/PostageCharges"
            element={<PostalCharges />}
          />
          {/* <Route path="Admin/Master/CartFilters" element={<CartFilters />} /> */}
          <Route
            path="/Admin/Master/PayPalSubscriptionPlans"
            element={<PayPalSubscriptionPlans />}
          />
          <Route path="/Admin/Master/Departments" element={<Departments />} />
          <Route path="/Admin/Master/Positions" element={<Positions />} />

          {/* SimplyNoted SN */}
          <Route path="/Admin/sn/styles" element={<Styles />} />
          <Route path="/Admin/sn/giftcards" element={<GiftCards />} />
          <Route path="/Admin/sn/addressbook" element={<Addressbook />} />
          <Route path="/Admin/sn/templates" element={<Templates />} />

          {/* LN */}
          <Route path="/admin/ln/templates" element={<LNTemplates />} />
          <Route path="/htmlpreview" element={<HTMLPreview />} />
          <Route path="/admin/ln/campaigns" element={<LNCampaign />} />
          <Route path="/campaignform" element={<Campaignform />} />
          <Route path="/campaignshistory" element={<LNCampaignHistory />} />
          <Route path="/campaignlocation" element={<CampaignLocation />} />
          <Route path="/campaigndetailstab" element={<CampaignDetailsTab />} />

          <Route path="/segments" element={<Segments />} />
          {/* <Route path="/globalsubmoduleassettypes" element={<GlobalSubModuleAssetTypes />} /> */}
          {/* <Route path="/globalassettypeassetsizes" element={<GlobalAssetTypeAssetSizes />} /> */}
          <Route path="/submoduleassettypes" element={<SubModuleAssetTypes />} />
          <Route path="/assettypeassetsizes" element={<AssetTypeAssetSizes />} />
          <Route path="/productcategories" element={<ProductCategories />} />
          <Route path="/productactions" element={<ProductActions />} />
          <Route path="/productcategorysubcategories" element={<ProductCategorySubCategories />} />
          <Route path="/productassets" element={<ProductAssets />} />
          <Route path="/userlocations" element={<UserLocations />} />
          <Route
            path="/locationmemberships"
            element={<LocationMemberships />}
          />
          <Route path="/userterritories" element={<UserTerritories />} />
          <Route path="/bl/st/banners" element={<Banners />} />
          <Route path="/productfilters" element={<ProductFilters />} />
          <Route
            path="/productfilterdetails"
            element={<ProductFiltersDetails />}
          />
          <Route path="/socialpost" element={<SocialPost />} />
          {/* <Route path="/misc/keywordchecked" element={<KeyWordChecked />} /> */}
          <Route path="/misc/welcomepopup" element={<WelcomePopup />} />

          <Route path="/lpreportdetails" element={<LPReportDetails />} />
          <Route path="/OrderReportIDs" element={<OrderReportIDs />} />
          <Route path="/OrderReports" element={<OrderReports />} />
          <Route path="/lphistory" element={<LPHistory />} />
          <Route path="/Impersonation" element={<Impersonation />} />

          <Route path="/LocationFilter" element={<LocationFilter />} />

          <Route path="*" element={<Navigate replace to="/adminhome" />} />
        </Routes>
      </ScreenLayout>
    );
  } else if (isLoggedIn && getItemStore(storageKeys.RoleName) == "2") {
    return (
      <div>
        <ScreenLayout>
          <Routes>
            <Route path="/MarketerLogin" element={<MarketerLogin />} />
            <Route path="/MarketerLocations" element={<MarketerLocations />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/termsofuse" element={<TermsOfUse />} />
          </Routes>
        </ScreenLayout>
      </div>
    );
  } else {
    if (!loading) {
      return (
        <div>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/Registration" element={<Registration />} />
            <Route path="/setPassword" element={<Setpassword />} />
            <Route path="/thankyou" element={<Thankyou />} />
            <Route path="*" element={<Navigate replace to="/login" />} />
          </Routes>
        </div>
      );
    } else {
      return (
        <div>
          <CommonLoader />
        </div>
      );
    }
  }
}

export default App;

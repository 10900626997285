import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useEffect } from 'react';
import { GrNext, GrPrevious } from "react-icons/gr";
import { Button, Text } from "../../components";
import { GetDateWithMyFormat, IsEmpty, getItemStore, storageKeys } from '../../config/appConstant';
import './CustomCalender.css';
import Style from "./CustomCalenderStyle";


const MiniCalendar = props => {
    const { classes, SeletedDate, calenderData, PostDate } = props

    const DAY = new Date();
    const MonthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [MonthData, setWeekDay] = React.useState([{ "ID": 1, "Name": "Sunday" }, { "ID": 2, "Name": "Monday" }, { "ID": 3, "Name": "Tuesday" }, { "ID": 4, "Name": "Wedsday" }, { "ID": 5, "Name": "Thursday" }, { "ID": 6, "Name": "Friday" }, { "ID": 7, "Name": "Saturday" }]);
    const [IsLoading, setIsLoading] = React.useState(true);
    const [CurrentDate, setCurrentDate] = React.useState(new Date().getDate());
    const [CurrentMonth, setCurrentMonth] = React.useState(DAY.getMonth() + 1);
    const [CurrentYear, setCurrentYear] = React.useState(DAY.getFullYear());
    const [CurrentMonthName, setCurrentMonthName] = React.useState(MonthNames[DAY.getMonth()]);
    const [TotalDayOfMonth, setTotalDayOfMonth] = React.useState([]);
    const [TotalCards, setTotalCards] = React.useState([]);
    const [firstDateWeek, setfirstDateWeek] = React.useState(new Date(DAY.getFullYear(), DAY.getMonth(), 1).getDay() + 1);
    const [BlurCards, setBlurCards] = React.useState(0);// for Card Color Blure purpose

    const coptext = "Find your fit. Schedule an appointment today for a trial of Bausch + Lomb contact lenses. Available at: Marietta Eye Clinic Kennestone (760)582-5345"
    useEffect(() => {
        let propsMonth = IsEmpty(PostDate) ? new Date().getMonth() + 1 : parseInt(PostDate.split("/")[0], 10)
        let propsYear = IsEmpty(PostDate) ? new Date().getFullYear() : parseInt(PostDate.split("/")[2], 10)
        setCurrentDate(IsEmpty(PostDate) ? CurrentDate : moment(PostDate).date())

        setCurrentMonthName(IsEmpty(PostDate) ? MonthNames[DAY.getMonth()] : MonthNames[propsMonth - 1])
        var current = new Date(propsYear, propsMonth, -1);

        GetCurrntMonth('First', propsMonth, propsYear)
    }, [])

    const GetCurrntMonth = (val, currentMonth, currentYear) => {
        setIsLoading(true)
        var current = new Date(currentYear, currentMonth - 1, 1);

        setCurrentMonth(currentMonth)
        setfirstDateWeek(current.getDay() + 1)
        setCurrentMonthName(MonthNames[current.getMonth()])
        setCurrentYear(current.getFullYear())

        var pairs = [];
        for (let i = 0; i < new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate(); i++) {
            pairs.push({ Date: i + 1 });
        }
        //Logic for Get TodalDays Of month from start Day of week to totalday
        var cards = [];
        let firstDay = new Date(current.getFullYear(), current.getMonth(), 1);
        let lastDay = new Date(current.getFullYear(), current.getMonth() + 1, 0);
        let StartDayofWeek = MonthData[firstDay.getDay()].Name
        let Indexxx = MonthData.findIndex(obj => obj.Name === StartDayofWeek);
        let TotalDaysOfMonth = pairs.length + Indexxx

        for (let i = 0; i < TotalDaysOfMonth; i++) {
            // new Date().getDate() == TotalCards[i].Date
            cards.push({ ID: i + 1, PreviewURL: 'https://cdn.mybauschreach.com/Images/AutomationAssets/30ad2c7-88d2-5f5-83f3-44706c55bfbc_thumb.png?v=1698642914' });
        }

        setTotalCards(cards)
        setBlurCards(Indexxx)
        setTotalDayOfMonth(pairs)
        setIsLoading(false)
        //-------------------------------------------------------------------

        // { val != 'First' && props.handleCalendar(GetDateWithMyFormat(firstDay), GetDateWithMyFormat(lastDay)) }//fetch date from Api -Call Api
    }

    const nextMonth = (date, currentMonth, currentYear) => {
        setIsLoading(true)
        setCurrentMonth(currentMonth + 1)
        var current = ''
        if (currentMonth == 11) {
            current = new Date(date.getFullYear() + 1, -1, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(MonthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        } else {
            current = new Date(date.getFullYear(), currentMonth, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(MonthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        }
        var daysInMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate();
        var pairs = [];
        for (let i = 0; i < daysInMonth; i++) {
            pairs.push({ Date: i + 1 });
        }

        //Logic for Get TodalDays Of month from start Day of week to totalday
        var cards = [];
        let firstDay = new Date(current.getFullYear(), current.getMonth(), 1);
        let lastDay = new Date(current.getFullYear(), current.getMonth() + 1, 0);
        let StartDayofWeek = MonthData[firstDay.getDay()].Name
        let Indexxx = MonthData.findIndex(obj => obj.Name === StartDayofWeek);
        let TotalDaysOfMonth = pairs.length + Indexxx
        for (let i = 0; i < TotalDaysOfMonth; i++) {
            cards.push({ ID: i + 1 });
        }
        setTotalCards(cards)
        setBlurCards(Indexxx)
        //-----------------------------------------------------------------
        // props.handleCalendar(GetCMMSDateWithMyFormat(firstDay), GetCMMSDateWithMyFormat(lastDay))//fetch date from Api -Call Api
        setTotalDayOfMonth(pairs)
        props.GetFilterMonthData(current.getMonth() + 1, current.getFullYear())
        // setIsLoading(false)
    }

    const previousMonth = (date, currentMonth, currentYear) => {
        setIsLoading(true)
        setCurrentMonth(currentMonth - 1)
        var current = ''
        if (currentMonth == 1) {
            current = new Date(date.getFullYear(), -1, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(MonthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        } else {
            current = new Date(date.getFullYear(), currentMonth - 2, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(MonthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        }

        var daysInMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate();
        var pairs = [];
        for (let i = 0; i < daysInMonth; i++) {
            pairs.push({ Date: i + 1 });
        }

        //Logic for Get TodalDays Of month from start Day of week to totalday
        var cards = [];
        let firstDay = new Date(current.getFullYear(), current.getMonth(), 1);
        let lastDay = new Date(current.getFullYear(), current.getMonth() + 1, 0);
        let StartDayofWeek = MonthData[firstDay.getDay()].Name
        let Indexxx = MonthData.findIndex(obj => obj.Name === StartDayofWeek);
        let TotalDaysOfMonth = pairs.length + Indexxx
        for (let i = 0; i < TotalDaysOfMonth; i++) {
            cards.push({ ID: i + 1 });
        }
        setTotalCards(cards)
        setBlurCards(Indexxx)
        //--------------------------------------------------------------------
        // props.handleCalendar(GetCMMSDateWithMyFormat(firstDay), GetCMMSDateWithMyFormat(lastDay))//fetch date from Api -Call Api
        setTotalDayOfMonth(pairs)
        props.GetFilterMonthData(current.getMonth() + 1, current.getFullYear())
        setIsLoading(false)
    }

    const OnPressNext = () => {
        const currentYear = new Date().getFullYear();
        if (CurrentYear == currentYear && CurrentMonth < 12) {
            nextMonth(new Date(), CurrentMonth, CurrentYear);
        }
    }
    const OnPressPrevious = () => {
        const currentYear = new Date().getFullYear();
        if (CurrentYear == currentYear && CurrentMonth > 1) {
            previousMonth(new Date(), CurrentMonth, CurrentYear)
        }
    }

    let monthName = MonthNames[CurrentMonth - 1];
    return (
        <>
            <div style={{ width: "300px", maxHeight: '270px', border: '1px solid #d3d3d3', borderRadius: '5px', overflow: 'hidden' }}>
                <div style={{ display: 'flex', width: '100%', margin: '10px 0px 15px 0px', alignItems: 'center', justifyContent: 'center' }}>
                    <div className={classes.CalenderButton} onClick={() => { OnPressPrevious(new Date(), CurrentMonth, CurrentYear); }} >
                        <GrPrevious style={{ fontSize: 15 }} />
                    </div>
                    <Text>{CurrentMonthName + " " + CurrentYear}<br /></Text>
                    <div className={classes.CalenderButton} onClick={() => { OnPressNext(new Date(), CurrentMonth, CurrentYear); }} >
                        <GrNext style={{ fontSize: 15 }} />
                    </div>

                </div>
                <div style={{ display: 'grid', height: '20px', gridTemplateColumns: '12.25% 12.25% 12.50% 12.50% 12.25% 12.50% 13%', gridTemplateRows: '30px 30px 30px 30px 30px 30px', gridGap: '2px 3px' }}>

                    {MonthData.map((Item, val) => {
                        return (
                            <div key={val} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Text subhead dividerColor semibold>{Item.Name[0]}</Text></div>
                        )
                    })}
                </div>
                <div style={{ display: 'grid', height: "200px", gridTemplateColumns: '12.25% 12.25% 12.50% 12.50% 12.25% 12.50% 13%', gridTemplateRows: '30px 30px 30px 30px 30px 30px', gridGap: '2px 3px' }}>
                    {TotalCards.map((item, index) => {
                        return (
                            <div key={index} style={{ textAlign: 'center', padding: '2px 4px', cursor: 'pointer' }}>
                                {calenderData.map((Item, Index) => {
                                    let CalenderDate = Item.DateOfCreation.split('-')[2]
                                    let CalenderFullDate = (moment(CalenderDate + "/" + CurrentMonthName + "/" + CurrentYear).format("MM/DD/YYYY"))
                                    return (
                                        Index == index - firstDateWeek + 1 &&
                                        <div key={Index} onClick={() => { SeletedDate(moment(CalenderDate + "/" + CurrentMonthName + "/" + CurrentYear).format("DD-MM-YYYY")); setCurrentDate(CalenderDate) }}>
                                            {Item.Assets.length >= 1 && Item.Assets[0].Status != 3
                                                ? <div className={monthName == CurrentMonthName && PostDate == CalenderFullDate ? 'dayDate-dote-active' : 'dayDate-dote'}></div>
                                                : <div className={monthName == CurrentMonthName && PostDate == CalenderFullDate ? classes.CalenderCurrentContent : classes.CalenderInnerContent}>
                                                    {CalenderDate}
                                                </div>
                                            }
                                        </div>)
                                })}
                            </div>
                        )
                    })}

                    {/* {TotalCards.map((Item, Index) => {
                        return (
                            <div style={{ textAlign: 'center', padding: '2px 4px', cursor: 'pointer' }} key={Index}>

                                {TotalDayOfMonth.map((item, index) => index == Index - firstDateWeek + 1 &&
                                    <div key={index}>
                                        <div onClick={() => { SeletedDate(moment(item.Date + "/" + CurrentMonthName + "/" + CurrentYear).format("DD-MM-YYYY")); setCurrentDate(item.Date) }} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                            <div className={monthName == CurrentMonthName && CurrentDate == item.Date ? classes.MiniCalenderCurrentContent : classes.MiniCalenderInnerContent}>
                                                <Text footnote BlackTextColor >{item.Date}</Text></div>
                                        </div>
                                        {/* {!IsEmpty(calenderData) && calenderData.map((Item) => {
                                            let CalenderDate = (moment(item.Date + "/" + CurrentMonthName + "/" + CurrentYear).format("YYYY-MM-DD"))

                                            return (
                                                <div key={Item.id}>
                                                    {(CalenderDate == Item.DateOfCreation && Item.Assets != '') &&
                                                        <div>
                                                            <div className="content-box-calendar">
                                                                <div className='box-image-calendar calendar-image-box' style={{
                                                                    backgroundImage: `url(${!IsEmpty(Item.Assets[0]) && Item.Assets[0].ImageURL})`
                                                                }}>
                                                                    <div className='button-hover'>
                                                                        <div className='button-field-calendar'><Button round Medium type={3} title='Preview' onClick={() => props.handlePopup(true, "Preview", "View Social Post", { Name: "URL", Data: "FVGHfkm, cvgy ikvhbk," })} /></div>
                                                                        <div className='button-field-calendar'><Button round Medium type={3} title='Edit' onClick={() => props.handlePopup(true, "Edit", "Reschedule Post", { Name: "URL", Data: "FVGHfkm, cvgy ikvhbk," })} /></div>
                                                                        <div className='button-field-calendar'><Button round Medium type={3} title='Add Post' onClick={() => props.handlePopup(true, "Add", "Add Social Post", { Name: "URL", Data: "FVGHfkm, cvgy ikvhbk," })} /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                </div>)
                                         })} /}
                                    </div>)}
                            </div>)
                    })} */}
                </div>
            </div>
        </>
    )
}

export default withStyles(Style)(MiniCalendar);